import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

import { Avatar } from 'primereact/avatar';
import { Column, ColumnProps } from 'primereact/column';
import { ContextMenu } from 'primereact/contextmenu';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Menu } from 'primereact/menu';
import { Skeleton } from 'primereact/skeleton';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';

import ErrorDisplay from '../../components/ErrorDisplay';
import Icon from '../../components/Icon';
import ListItem from '../../components/ListItem';
import RookieButton from '../../components/RookieButton';

import { UserDetails } from '../../types/user';
import { Roles } from '../../types/roles';
import { ERROR_TYPES } from '../../types/common';

interface Props {
    data: UserDetails[];
    focusedUser: UserDetails | null;
    isError: boolean;
    isFetching: boolean;
    isLoading: boolean;
    onClickUser: (userID: string) => void;
    onCloseDeleteDialog: () => void;
    onDeleteUser: (userID: string) => void;
    onFocusUser: (user: UserDetails) => void;
    onLoadMore?: () => void;
    onShowDeleteDialog: () => void;
    onViewUser: (userID: string) => void;
    roles: Roles;
    showDeleteDialog: boolean;
    showPagination: boolean;
    permissions: {
        canCreate: boolean;
        canDelete: boolean;
        canEdit: boolean;
        canView: boolean;
    };
}

const StaffActiveView = (props: Props) => {
    const { focusedUser, isError, isFetching, isLoading } = props;

    const rowMenu = useRef<Menu>(null);
    const rowContextMenu = useRef<ContextMenu>(null);
    const staffFormToast = useRef<Toast>(null);

    const navigate = useNavigate();

    const focusedUserID = focusedUser?.userID;

    const titleTemplate = (row: UserDetails) => {
        return (
            <ListItem
                title={row.userDetails.name}
                start={
                    <Avatar
                        image={row.userDetails.picture}
                        label={row.userDetails.name}
                    />
                }
                compact
            />
        );
    };

    const actionTemplate = (row: UserDetails) => {
        return (
            <div className="p-buttonset">
                <RookieButton
                    severity="secondary"
                    text={true}
                    className="td-action"
                    aria-label="More"
                    onClick={(e) => {
                        if (rowMenu.current) {
                            rowMenu.current.toggle(e);
                        }
                        props.onFocusUser(row);
                    }}
                    icon="more_horiz"
                />
            </div>
        );
    };

    const statusTemplate = (row: UserDetails) => {
        const teamStatus = {
            Active: 'Active',
            Archived: 'Archived',
            Deleted: 'Deleted',
        };

        const severity = (
            teamStatus as {
                [index: string]:
                    | 'success'
                    | 'info'
                    | 'warning'
                    | 'danger'
                    | null
                    | undefined;
            }
        )[row.entityStatus];

        return <Tag severity={severity}>{row.entityStatus}</Tag>;
    };

    const getMenuItems = () => {
        let items = [];

        const isOwner = focusedUser?.roles.some(
            (role) =>
                role.roleName === 'Teams Owner' ||
                role.roleName === 'Organisations Owner'
        );

        if (props.permissions.canDelete && !isOwner) {
            items.push({
                label: 'Delete Staff',
                icon: <Icon name="delete" className="p-menuitem-icon" />,
                command: () => props.onShowDeleteDialog(),
            });
        }

        return items;
    };

    const columnSchema: ColumnProps[] = [
        {
            body: isLoading ? <Skeleton /> : titleTemplate,
            field: 'given_name',
            header: 'Name',
        },
        {
            body: isLoading ? (
                <Skeleton />
            ) : (
                (row: UserDetails) => row.roles[0].roleName
            ),
            field: 'role',
            header: 'Role',
        },
        {
            body: isLoading ? <Skeleton /> : statusTemplate,
            field: 'teamStatus',
            header: 'Status',
        },
        {
            body: isLoading ? (
                <Skeleton />
            ) : (
                (row) =>
                    row.userDetails.last_login &&
                    format(new Date(row.userDetails.last_login), 'dd/MM/yyyy')
            ),
            field: 'last_login',
            header: 'Last Login',
        },
        ...(getMenuItems().length > 0
            ? [
                  {
                      body: isLoading ? <Skeleton /> : actionTemplate,
                      className: 'actions-td',
                      field: 'action',
                      header: '',
                  },
              ]
            : []),
    ];

    const columns = columnSchema.map((col: ColumnProps) => {
        return <Column key={col.field} {...col} />;
    });

    // empty array to populate rows for skeleton loading components
    const blankRows = Array(5);

    const deleteModalContent = props.focusedUser && (
        <div>
            <p className="delete-msg">
                Are you sure you want to remove this staff member?
            </p>
            <div className="list">
                <ListItem
                    start={
                        <Avatar
                            image={props.focusedUser.userDetails.picture}
                            label={props.focusedUser.userDetails.name}
                        />
                    }
                    caption={props.focusedUser.roles[0].roleName}
                    title={props.focusedUser.userDetails.name}
                />
            </div>
            <div className="form-actions">
                <RookieButton
                    text={true}
                    severity="secondary"
                    onClick={() => props.onCloseDeleteDialog()}
                    type="button"
                    icon="cancel"
                    label="Cancel"
                />
                <RookieButton
                    severity="danger"
                    type="submit"
                    onClick={() =>
                        focusedUserID && props.onDeleteUser(focusedUserID)
                    }
                    icon="delete"
                    label="Remove Staff"
                />
            </div>
        </div>
    );

    const tableEmptyMessage = (
        <ErrorDisplay
            actions={
                isError
                    ? [
                          {
                              command: () => navigate(0), // refresh
                              icon: 'refresh',
                              label: 'Retry',
                          },
                      ]
                    : []
            }
            alignment="middle"
            errorType={ERROR_TYPES.notFound}
            hasReturn={false}
            proportion="compact"
            title={isError ? 'No data returned' : `No staff found`}
            desc={
                isError
                    ? 'Refresh to try the request again'
                    : "To get started select 'Add Staff' in the top right area."
            }
        />
    );

    const footerTemplate = () => {
        return (
            <RookieButton
                onClick={props.onLoadMore}
                severity="secondary"
                label="Load more"
                icon="pending"
            />
        );
    };

    return (
        <>
            <DataTable
                value={isLoading ? blankRows : props.data}
                loading={isFetching && !isLoading}
                footer={
                    !isFetching && props.showPagination ? footerTemplate : null
                }
                emptyMessage={tableEmptyMessage}
                selectionMode="single"
                onSelectionChange={(e) => {
                    props.onClickUser(e.value.userID);
                    props.onFocusUser(e.value);
                }}
                onContextMenuSelectionChange={(e) => props.onFocusUser(e.value)}
                onContextMenu={(e) => {
                    if (rowContextMenu.current) {
                        rowContextMenu.current.show(e.originalEvent);
                    }
                    if (rowMenu.current) {
                        rowMenu.current.hide(e.originalEvent);
                    }
                }}
                columnResizeMode="expand"
                resizableColumns
            >
                {isLoading ? columns : props.data.length > 0 ? columns : null}
            </DataTable>

            <Dialog
                className="member-form-modal delete-modal"
                header="Delete Staff"
                onHide={props.onCloseDeleteDialog}
                visible={props.showDeleteDialog}
            >
                {deleteModalContent}
            </Dialog>

            <Menu model={getMenuItems()} popup ref={rowMenu} />
            <ContextMenu model={getMenuItems()} ref={rowContextMenu} />
            <Toast ref={staffFormToast} />
        </>
    );
};

export default StaffActiveView;
