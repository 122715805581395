import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';

import ErrorDisplay from '../../components/ErrorDisplay';
import PageHeader from '../../layout/PageHeader';
import RookieButton from '../../components/RookieButton';

import { Column, ColumnProps } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Menu } from 'primereact/menu';
import { Skeleton } from 'primereact/skeleton';

import { Season } from '../../types/seasons';
import { ERROR_TYPES } from '../../types/common';

import SeasonSettings from './SeasonSettings';
import SeasonForm from './SeasonForm';
import PageContainer from '../../layout/PageContainer';
import { Sidebar } from 'primereact/sidebar';

interface Props {
    data: Season[];
    isError: boolean;
    isLoading: boolean;
    isFetching: boolean;
    onCloseSeasonDialog: () => void;
    onCloseSettingsDialog: () => void;
    onCreate: () => void;
    onDelete: () => void;
    onLoadMore: () => void;
    onSelect: (season: Season) => void;
    onShowSeasonDialog: () => void;
    onShowSettingsDialog: () => void;
    onUpdate: () => void;
    setFocus: (season: Season) => void;
    selectedSeason: Season | null;
    showPagination: boolean;
    showSeasonDialog: boolean;
    showSettingsDialog: boolean;
}

const AssociationSeasonsView = ({
    data,
    isFetching,
    isLoading,
    onCloseSeasonDialog,
    onCloseSettingsDialog,
    onCreate,
    onDelete,
    onLoadMore,
    onSelect,
    onUpdate,
    selectedSeason,
    setFocus,
    showPagination,
    showSettingsDialog,
    showSeasonDialog,
}: Props) => {
    const { associationID } = useParams();
    const rowMenu = useRef<Menu>(null);

    const footerTemplate = (
        <RookieButton
            onClick={onLoadMore}
            severity="secondary"
            label="Load more"
            icon="pending"
        />
    );

    const emptyTemplate = (
        <ErrorDisplay
            alignment="middle"
            desc={`Seasons will appear here.`}
            errorType={ERROR_TYPES.empty}
            hasReturn={false}
            proportion="compact"
            title="No Seasons"
        />
    );

    const actionTemplate = (row: Season) => {
        return (
            <div className="p-buttonset">
                <RookieButton
                    severity="secondary"
                    text={true}
                    className="td-action"
                    aria-label="More"
                    onClick={(e) => {
                        if (rowMenu.current) {
                            rowMenu.current.toggle(e);
                        }
                        setFocus(row);
                    }}
                    icon="more_horiz"
                />
            </div>
        );
    };

    const columns = [
        {
            field: 'seasonName',
            header: 'Name',
            body: isLoading && <Skeleton />,
        },
        {
            field: 'createdAt',
            header: 'Created',
            body: (row: Season) =>
                isLoading ? (
                    <Skeleton />
                ) : (
                    format(new Date(row.createdAt), 'MMM yyyy')
                ),
        },
        {
            body: isLoading ? <Skeleton /> : actionTemplate,
            className: 'actions-td',
            field: 'action',
            header: '',
        },
    ];

    const rowActions = [
        {
            label: 'Edit',
            command: onUpdate,
        },
        {
            label: 'Delete',
            command: onDelete,
        },
    ];

    return (
        <PageContainer>
            <PageHeader
                title="Seasons"
                actions={[
                    {
                        label: 'Create Season',
                        command: onCreate,
                    },
                ]}
            />
            <DataTable
                value={isLoading ? Array(5) : data}
                loading={isFetching && !isLoading}
                footer={!isFetching && showPagination ? footerTemplate : null}
                emptyMessage={emptyTemplate}
                selectionMode="single"
                onSelectionChange={(e) => {
                    onSelect(e.value);
                }}
                columnResizeMode="expand"
                resizableColumns
            >
                {(isLoading || data.length > 0) &&
                    columns.map((col: ColumnProps) => (
                        <Column key={col.field} {...col} />
                    ))}
            </DataTable>

            <Menu model={rowActions} popup ref={rowMenu} />

            <Dialog
                header={selectedSeason ? 'Edit Season' : 'Create Season'}
                onHide={onCloseSeasonDialog}
                visible={showSeasonDialog}
            >
                <SeasonForm
                    seasonID={selectedSeason?.seasonID}
                    associationID={associationID}
                    initialValues={selectedSeason}
                    onSuccess={onCloseSeasonDialog}
                    actions={[
                        {
                            label: 'Cancel',
                            type: 'button',
                            className: 'p-button-secondary',
                            onClick: onCloseSeasonDialog,
                        },
                    ]}
                />
            </Dialog>

            <Sidebar
                header="Season Settings"
                onHide={onCloseSettingsDialog}
                visible={showSettingsDialog}
                position="right"
            >
                <SeasonSettings
                    seasonID={selectedSeason?.seasonID}
                    associationID={associationID}
                    initialValues={selectedSeason}
                    onSuccess={onCloseSettingsDialog}
                    actions={[
                        {
                            label: 'Cancel',
                            type: 'button',
                            className: 'p-button-secondary',
                            onClick: onCloseSettingsDialog,
                        },
                    ]}
                />
            </Sidebar>
        </PageContainer>
    );
};

export default AssociationSeasonsView;
