import { isArray } from 'lodash';

// Pages

import LogOut from './LogOut';
import CalendarContainer from '../pages/events/CalendarContainer';
import StaffContainer from '../pages/staff/StaffContainer';
import PlayersContainer from '../pages/players/PlayersContainer';
import TeamsImport from '../pages/teams/TeamsImport';
import TeamListingContainer from '../pages/teams/TeamListingContainer';
import EntityListingContainer from '../pages/users/EntityListingContainer';
import OrganisationsImport from '../pages/organisations/OrganisationsImport';
import TeamHome from '../pages/teams/TeamHome';
import OrganisationHome from '../pages/organisations/OrganisationHome';
import UserHome from '../pages/users/UserHome';
import TeamSettings from '../pages/teams/TeamSettings';
import OrganisationSettings from '../pages/organisations/OrganisationSettings';
import TeamForm from '../pages/teams/TeamForm';
import EventsContainer from '../pages/events/EventsContainer';
import EventsSingleContainer from '../pages/events/EventSingleContainer';

import { Permissions } from '../types/permissions';
import { Route } from '../types/route';

import TeamBreadcrumb from '../pages/teams/TeamBreadcrumb';
import OrganisationBreadcrumb from '../pages/organisations/OrganisationBreadcrumb';
import EventBreadcrumb from '../pages/events/EventBreadcrumb';
import UserBreadcrumb from '../pages/users/UserBreadcrumb';

import EventDetails from '../pages/events/EventDetails';
import EventReports from '../pages/events/EventReports';

import GameTimeBasicReport from '../pages/reports/GameTimeBasicReport';
import GameTimeAdvancedReport from '../pages/reports/GameTimeAdvancedReport';
import GameTimeExpertReport from '../pages/reports/GameTimeExpertReport';
import GameTeamStatsBasicReport from '../pages/reports/GameTeamStatsBasicReport';
import GameTeamStatsAdvancedReport from '../pages/reports/GameTeamStatsAdvancedReport';
import GamePlayerStatsBasicReport from '../pages/reports/GamePlayerStatsBasicReport';
import GamePlayerStatsAdvancedReport from '../pages/reports/GamePlayerStatsAdvancedReport';
import TicketContainer from '../pages/tickets/TicketContainer';

import AssociationSettings from '../pages/associations/AssociationSettings';
import AssociationHome from '../pages/associations/AssociationHome';
import AssociationBreadcrumb from '../pages/associations/AssociationBreadcrumb';
import OrganisationListingContainer from '../pages/organisations/OrganisationListingContainer';

import TimeReport from '../pages/reportsTeamSeason/TimeReport';
import TeamStatsReport from '../pages/reportsTeamSeason/TeamStatsReport';
import TeamSeasonReportsListing from '../pages/reportsTeamSeason/TeamSeasonReportsListing';
import OrgSeasonReportsListing from '../pages/reportsTeamSeason/OrgSeasonReportsListing';
import AssocSeasonReportsListing from '../pages/reportsTeamSeason/AssocSeasonReportsListing';
import PlayerStatsReport from '../pages/reportsTeamSeason/PlayerStatsReport';
import GameSummaryReport from '../pages/reportsTeamSeason/GameSummaryReport';
import BenchStartReport from '../pages/reportsTeamSeason/TeamSeasonBenchStarts';
import OrgTeamSummaryReport from '../pages/reportsTeamSeason/OrgTeamSummaryReport';
import OrgTeamPGTReport from '../pages/reportsTeamSeason/OrgTeamPGTReport';
import AssocTeamSummaryReport from '../pages/reportsTeamSeason/AssocTeamSummaryReport';

import TeamSeasonsContainer from '../pages/seasons/TeamSeasonsContainer';
import TeamSeasonsOwnedContainer from '../pages/seasons/TeamSeasonsOwnedContainer';
import TeamSeasonsParticipatingContainer from '../pages/seasons/TeamSeasonsParticipatingContainer';
import AssociationSeasonsContainer from '../pages/seasons/AssociationSeasonsContainer';
import SeasonSingleContainer from '../pages/seasons/SeasonSingleContainer';
import SeasonTeamsContainer from '../pages/seasons/SeasonTeamsContainer';
import SeasonSettingsContainer from '../pages/seasons/SeasonSettingsContainer';
import SeasonBreadcrumb from '../pages/seasons/SeasonBreadcrumb';

import LicencesContainer from '../pages/licences/LicencesContainer';
import OrganisationLicencesContainer from '../pages/licences/OrganisationLicencesContainer';
import OrganisationLicenceSingleContainer from '../pages/licences/OrganisationLicenceSingleContainer';
import SubscriptionsContainer from '../pages/subscriptions/SubscriptionsContainer';
import SubscriptionSingleContainer from '../pages/subscriptions/SubscriptionSingleContainer';
import AssociationLicencesContainer from '../pages/licences/AssociationLicencesContainer';
import AssociationLicenceSingleContainer from '../pages/licences/AssociationLicenceSingleContainer';
import CompleteAccountContainer from '../pages/account/CompleteAccountContainer';
import AccountSelector from './AccountSelector';
import GameInterchangeOnOffReport from '../pages/reports/GameInterchangeOnOffReport';
import GameInterchangePlayerReport from '../pages/reports/GameInterchangePlayerReport';
import CheckoutSuccess from '../pages/users/CheckoutSuccess';
import TeamJoinCode from '../pages/teams/TeamJoinCode';
import AccountContainer from '../pages/account/AccountContainer';
import EventInvitees from '../pages/events/EventInvitees';
import NotesContainer from '../pages/notes/NotesContainer';
import PostsContainer from '../pages/posts/PostsContainer';

// Helpers

export const ASSOCIATION_ROUTE = 'a';
export const ASSOCIATION_PARAM = 'associationID';
export const ORGANISATION_ROUTE = 'o';
export const ORGANISATION_PARAM = 'organisationID';
export const TEAM_ROUTE = 't';
export const TEAM_PARAM = 'teamID';
export const USERS_PARAM = 'userID';
export const USERS_ROUTE = 't';

interface MapType {
    [roleName: string]: {
        urlSegment: string;
        urlParam: string;
    };
}

export const entityMap: MapType = {
    associations: {
        urlSegment: ASSOCIATION_ROUTE,
        urlParam: 'ASSOCIATION_PARAM',
    },
    organisations: {
        urlSegment: ORGANISATION_ROUTE,
        urlParam: ORGANISATION_PARAM,
    },
    teams: {
        urlSegment: TEAM_ROUTE,
        urlParam: TEAM_PARAM,
    },
    users: {
        urlSegment: USERS_ROUTE,
        urlParam: USERS_PARAM,
    },
};

// Routes

const globalRoutes: Route[] = [
    {
        id: 'root',
        title: 'Home',
        path: '/',
        breadcrumb: 'Home',
        permissions: [],
        component: UserHome,
        authGateway: 'private',
    },
    {
        id: 'logout',
        title: 'Log Out',
        path: '/logout',
        permissions: [],
        component: LogOut,
        authGateway: 'public',
    },
    {
        id: 'completeAccount',
        title: 'Complete Account',
        path: '/signin/complete',
        permissions: [],
        component: CompleteAccountContainer,
        authGateway: 'session',
    },
    {
        id: 'accountSelector',
        title: 'Select Account',
        path: '/signin/account',
        permissions: [],
        component: AccountSelector,
        authGateway: 'private',
    },
    {
        id: 'checkoutSuccess',
        title: 'Success',
        path: '/checkout-success',
        permissions: [],
        component: CheckoutSuccess,
        authGateway: 'private',
    },
];

const userRoutes: Route[] = [
    {
        id: 'user-home',
        title: 'Home',
        path: '/u/:userID',
        permissions: [],
        component: UserHome,
        breadcrumb: UserBreadcrumb,
        authGateway: 'private',
    },
    {
        id: 'user-entities',
        title: 'Entities',
        path: '/u/:userID/entities/*',
        permissions: [],
        component: EntityListingContainer,
        authGateway: 'private',
    },
    {
        id: 'user-events',
        title: 'Events',
        path: '/u/:userID/events',
        permissions: [],
        component: CalendarContainer,
        authGateway: 'private',
    },
    {
        id: 'user-profile',
        title: 'Profile',
        path: '/u/:userID/profile',
        permissions: [],
        component: AccountContainer,
        authGateway: 'private',
    },
    {
        id: 'user-subscriptions',
        title: 'Subscriptions',
        path: '/u/:userID/subscriptions',
        permissions: [],
        component: SubscriptionsContainer,
        authGateway: 'private',
    },
    {
        id: 'user-subscription',
        title: 'Subscription',
        path: '/u/:userID/subscriptions/:subscriptionID',
        permissions: [],
        component: SubscriptionSingleContainer,
        breadcrumb: null,
        authGateway: 'private',
    },
    {
        id: 'user-teams-import',
        title: 'Team',
        path: '/u/:userID/teams/import',
        permissions: [],
        component: TeamsImport,
        authGateway: 'private',
    },
    {
        id: 'user-organisations-import',
        title: 'Organisations',
        path: '/u/:userID/organisations/import',
        permissions: [],
        component: OrganisationsImport,
        authGateway: 'private',
    },
    {
        id: 'tickets',
        title: 'Tickets',
        path: '/tickets/:ticketID/:action',
        permissions: [],
        component: TicketContainer,
        authGateway: 'private',
    },
    {
        id: 'user-team-join',
        title: 'Teams',
        path: '/u/:userID/team-join',
        permissions: [],
        component: TeamJoinCode,
        authGateway: 'private',
    },
];

const teamRoutes = (idPrefix = '', urlPrefix = ''): Route[] => [
    {
        id: `${idPrefix}teams-home`,
        path: `${urlPrefix}/t/:teamID`,
        permissions: [],
        icon: 'home',
        title: 'Team',
        component: TeamHome,
        breadcrumb: TeamBreadcrumb,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}teams-events`,
        path: `${urlPrefix}/t/:teamID/events`,
        icon: 'event',
        title: 'Events',
        permissions: [
            Permissions.GET_TEAMS,
            Permissions.GET_TEAMS_ALL,
            Permissions.GET_TEAMS_EVENTS,
            Permissions.MANAGE_TEAMS_EVENTS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        component: EventsContainer,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}teams-event`,
        path: `${urlPrefix}/t/:teamID/events/:eventID`,
        icon: 'event',
        title: 'Events',
        breadcrumb: EventBreadcrumb,
        permissions: [
            Permissions.GET_TEAMS,
            Permissions.GET_TEAMS_ALL,
            Permissions.GET_TEAMS_EVENTS,
            Permissions.MANAGE_TEAMS_EVENTS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        component: EventsSingleContainer,
        authGateway: 'private',
        children: [
            {
                id: `${idPrefix}teams-event-details`,
                path: 'details',
                icon: 'event',
                title: 'Details',
                permissions: [],
                component: EventDetails,
                breadcrumb: null,
                authGateway: 'private',
            },
            {
                id: `${idPrefix}teams-event-invitees`,
                path: 'invitees',
                icon: 'event',
                title: 'Invitees',
                permissions: [],
                component: EventInvitees,
                breadcrumb: null,
                authGateway: 'private',
            },
            {
                id: `${idPrefix}teams-event-reports`,
                path: 'reports',
                icon: 'event',
                title: 'Reports',
                permissions: [],
                component: EventReports,
                showBreadcrumb: false,
                breadcrumb: null,
                authGateway: 'private',
            },
            {
                id: `${idPrefix}teams-event-reports-gameTimeBasic`,
                path: 'reports/gt-basic',
                icon: 'event',
                title: 'Game Time Basic',
                permissions: [],
                component: GameTimeBasicReport,
                breadcrumb: null,
                authGateway: 'private',
            },
            {
                id: `${idPrefix}teams-event-reports-gameTimeAdvanced`,
                path: 'reports/gt-advanced',
                icon: 'event',
                title: 'Game Time Advanced',
                permissions: [],
                component: GameTimeAdvancedReport,
                breadcrumb: null,
                authGateway: 'private',
            },
            {
                id: `${idPrefix}teams-event-reports-gameTimeExpert`,
                path: 'reports/gt-expert',
                icon: 'event',
                title: 'Game Time Expert',
                permissions: [],
                component: GameTimeExpertReport,
                breadcrumb: null,
                authGateway: 'private',
            },
            {
                id: `${idPrefix}teams-event-reports-interchange-on-off`,
                path: 'reports/interchange',
                icon: 'event',
                title: 'Game Interchange On/Off',
                permissions: [],
                component: GameInterchangeOnOffReport,
                breadcrumb: null,
                authGateway: 'private',
            },
            {
                id: `${idPrefix}teams-event-reports-interchange-player`,
                path: 'reports/interchange-player',
                icon: 'event',
                title: 'Game Interchange Player',
                permissions: [],
                component: GameInterchangePlayerReport,
                breadcrumb: null,
                authGateway: 'private',
            },
            {
                id: `${idPrefix}teams-event-reports-gameTeamStatsBasic`,
                path: 'reports/ts-basic',
                icon: 'event',
                title: 'Team Stats Basic',
                permissions: [],
                component: GameTeamStatsBasicReport,
                breadcrumb: null,
                authGateway: 'private',
            },
            {
                id: `${idPrefix}teams-event-reports-gameTeamStatsAdvanced`,
                path: 'reports/ts-advanced',
                icon: 'event',
                title: 'Team Stats Advanced',
                permissions: [],
                component: GameTeamStatsAdvancedReport,
                breadcrumb: null,
                authGateway: 'private',
            },
            {
                id: `${idPrefix}teams-event-reports-gamePlayerStatsBasic`,
                path: 'reports/ps-basic',
                icon: 'event',
                title: 'Player Stats Basic',
                permissions: [],
                component: GamePlayerStatsBasicReport,
                breadcrumb: null,
                authGateway: 'private',
            },
            {
                id: `${idPrefix}teams-event-reports-gamePlayerStatsAdvanced`,
                path: 'reports/ps-advanced',
                icon: 'event',
                title: 'Player Stats Advanced',
                permissions: [],
                component: GamePlayerStatsAdvancedReport,
                breadcrumb: null,
                authGateway: 'private',
            },
        ],
    },
    {
        id: `${idPrefix}teams-staff`,
        path: `${urlPrefix}/t/:teamID/staff`,
        icon: 'manage_accounts',
        title: 'Staff',
        permissions: [
            Permissions.GET_TEAMS,
            Permissions.GET_TEAMS_ALL,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        component: StaffContainer,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}teams-players`,
        path: `${urlPrefix}/t/:teamID/players`,
        title: 'Players',
        icon: 'group',
        permissions: [
            Permissions.GET_TEAMS,
            Permissions.GET_TEAMS_ALL,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        component: PlayersContainer,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}teams-posts`,
        path: `${urlPrefix}/t/:teamID/posts`,
        title: 'Posts',
        icon: 'post',
        permissions: [
            Permissions.GET_TEAMS,
            Permissions.GET_TEAMS_ALL,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        component: PostsContainer,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}teams-reports`,
        path: `${urlPrefix}/t/:teamID/reports`,
        icon: 'bar_chart',
        title: 'Season Reports',
        permissions: [
            Permissions.GET_TEAMS,
            Permissions.GET_TEAMS_ALL,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        component: TeamSeasonReportsListing,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}teams-reports-summary`,
        path: `${urlPrefix}/t/:teamID/reports/summary`,
        icon: 'bar_chart',
        title: 'Reports',
        permissions: [
            Permissions.GET_TEAMS,
            Permissions.GET_TEAMS_ALL,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        component: GameSummaryReport,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}teams-reports-bench-starts`,
        path: `${urlPrefix}/t/:teamID/reports/bench-starts`,
        icon: 'bar_chart',
        title: 'Reports',
        permissions: [
            Permissions.GET_TEAMS,
            Permissions.GET_TEAMS_ALL,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        component: BenchStartReport,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}teams-reports-time`,
        path: `${urlPrefix}/t/:teamID/reports/time-basic`,
        icon: 'bar_chart',
        title: 'Reports',
        permissions: [
            Permissions.GET_TEAMS,
            Permissions.GET_TEAMS_ALL,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        component: TimeReport,
        props: {
            reportType: 'basic',
        },
        authGateway: 'private',
    },
    {
        id: `${idPrefix}teams-reports-time`,
        path: `${urlPrefix}/t/:teamID/reports/time-advanced`,
        icon: 'bar_chart',
        title: 'Reports',
        permissions: [
            Permissions.GET_TEAMS,
            Permissions.GET_TEAMS_ALL,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        component: TimeReport,
        props: {
            reportType: 'advanced',
        },
        authGateway: 'private',
    },
    {
        id: `${idPrefix}teams-reports-time`,
        path: `${urlPrefix}/t/:teamID/reports/time-expert`,
        icon: 'bar_chart',
        title: 'Reports',
        permissions: [
            Permissions.GET_TEAMS,
            Permissions.GET_TEAMS_ALL,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        component: TimeReport,
        props: {
            reportType: 'expert',
        },
        authGateway: 'private',
    },
    {
        id: `${idPrefix}teams-reports-team`,
        path: `${urlPrefix}/t/:teamID/reports/teams-basic`,
        icon: 'bar_chart',
        title: 'Reports',
        permissions: [
            Permissions.GET_TEAMS,
            Permissions.GET_TEAMS_ALL,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        component: TeamStatsReport,
        props: {
            reportType: 'basic',
        },
        authGateway: 'private',
    },
    {
        id: `${idPrefix}teams-reports-team`,
        path: `${urlPrefix}/t/:teamID/reports/teams-advanced`,
        icon: 'bar_chart',
        title: 'Reports',
        permissions: [
            Permissions.GET_TEAMS,
            Permissions.GET_TEAMS_ALL,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        component: TeamStatsReport,
        props: {
            reportType: 'advanced',
        },
        authGateway: 'private',
    },
    {
        id: `${idPrefix}teams-reports-player`,
        path: `${urlPrefix}/t/:teamID/reports/player-basic`,
        icon: 'bar_chart',
        title: 'Reports',
        permissions: [
            Permissions.GET_TEAMS,
            Permissions.GET_TEAMS_ALL,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        component: PlayerStatsReport,
        props: {
            reportType: 'basic',
        },
        authGateway: 'private',
    },
    {
        id: `${idPrefix}teams-reports-player`,
        path: `${urlPrefix}/t/:teamID/reports/player-advanced`,
        icon: 'bar_chart',
        title: 'Reports',
        permissions: [
            Permissions.GET_TEAMS,
            Permissions.GET_TEAMS_ALL,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        component: PlayerStatsReport,
        props: {
            reportType: 'advanced',
        },
        authGateway: 'private',
    },
    {
        id: `${idPrefix}teams-reports-player-advanced`,
        path: `${urlPrefix}/t/:teamID/reports/player-advanced`,
        icon: 'event',
        title: 'Player Stats Advanced',
        permissions: [
            Permissions.GET_TEAMS,
            Permissions.GET_TEAMS_ALL,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        component: GamePlayerStatsAdvancedReport,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}teams-seasons`,
        path: `${urlPrefix}/t/:teamID/seasons`,
        icon: 'event_upcoming',
        title: 'Seasons',
        permissions: [
            Permissions.GET_TEAMS,
            Permissions.GET_TEAMS_ALL,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        component: TeamSeasonsContainer,
        authGateway: 'private',
        children: [
            {
                id: `${idPrefix}teams-seasons-owned`,
                path: 'owned',
                icon: 'event_upcoming',
                title: 'My Seasons',
                permissions: [],
                breadcrumb: null,
                component: TeamSeasonsOwnedContainer,
                authGateway: 'private',
            },
            {
                id: `${idPrefix}teams-seasons-participating`,
                path: 'participating',
                icon: 'event_upcoming',
                title: 'Participating Seasons',
                permissions: [],
                breadcrumb: null,
                component: TeamSeasonsParticipatingContainer,
                authGateway: 'private',
            },
        ],
    },
    {
        id: `${idPrefix}teams-licences`,
        path: `${urlPrefix}/t/:teamID/licences`,
        icon: 'key',
        title: 'Licences',
        permissions: [
            Permissions.GET_TEAMS,
            Permissions.GET_TEAMS_ALL,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        component: LicencesContainer,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}teams-notes`,
        path: `${urlPrefix}/t/:teamID/notes/:noteID?`,
        icon: 'note',
        title: 'Notes',
        permissions: [
            Permissions.GET_TEAMS,
            Permissions.GET_TEAMS_ALL,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        component: NotesContainer,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}teams-settings`,
        path: `${urlPrefix}/t/:teamID/settings`,
        icon: 'settings',
        title: 'Settings',
        permissions: [
            Permissions.GET_TEAMS,
            Permissions.GET_TEAMS_ALL,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        component: TeamSettings,
        authGateway: 'private',
    },
];

const organisationRoutes = (idPrefix = '', urlPrefix = ''): Route[] => [
    {
        id: `${idPrefix}organisations-home`,
        path: `${urlPrefix}/o/:organisationID`,
        icon: 'home',
        title: 'Organisation',
        permissions: [],
        breadcrumb: OrganisationBreadcrumb,
        component: OrganisationHome,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}organisations-events`,
        path: `${urlPrefix}/o/:organisationID/events`,
        icon: 'event',
        title: 'Events',
        permissions: [
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        component: EventsContainer,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}organisations-staff`,
        path: `${urlPrefix}/o/:organisationID/staff`,
        icon: 'manage_accounts',
        title: 'Staff',
        permissions: [
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        component: StaffContainer,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}organisations-teams`,
        path: `${urlPrefix}/o/:organisationID/teams`,
        icon: 'group',
        title: 'Teams',
        permissions: [
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        component: TeamListingContainer,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}organisations-teams-create`,
        path: `${urlPrefix}/o/:organisationID/teams/new`,
        icon: 'add',
        title: 'Teams',
        permissions: [
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        component: TeamForm,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}organisations-teams-import`,
        path: `${urlPrefix}/o/:organisationID/teams/import`,
        icon: 'upload',
        title: 'Import',
        permissions: [
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        component: TeamsImport,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}organisations-reports`,
        path: `${urlPrefix}/o/:organisationID/reports`,
        icon: 'bar_chart',
        title: 'Season Reports',
        permissions: [
            Permissions.GET_TEAMS,
            Permissions.GET_TEAMS_ALL,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        component: OrgSeasonReportsListing,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}organisations-reports-summary`,
        path: `${urlPrefix}/o/:organisationID/reports/summary`,
        icon: 'bar_chart',
        title: 'Season Report',
        permissions: [
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        component: OrgTeamSummaryReport,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}organisations-reports-fair-play`,
        path: `${urlPrefix}/o/:organisationID/reports/fair-play`,
        icon: 'bar_chart',
        title: 'Season Report',
        permissions: [
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        component: OrgTeamPGTReport,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}organisations-licences`,
        path: `${urlPrefix}/o/:organisationID/licences`,
        icon: 'key',
        title: 'Licences',
        permissions: [
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        component: OrganisationLicencesContainer,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}organisations-licence`,
        path: `${urlPrefix}/o/:organisationID/licences/:licenceGroupID`,
        icon: 'key',
        title: 'Licences',
        permissions: [
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        breadcrumb: null,
        component: OrganisationLicenceSingleContainer,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}organisation-notes`,
        path: `${urlPrefix}/o/:organisationID/notes/:noteID?`,
        icon: 'note',
        title: 'Notes',
        permissions: [
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        component: NotesContainer,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}organisations-settings`,
        path: `${urlPrefix}/o/:organisationID/settings`,
        icon: 'settings',
        title: 'Settings',
        permissions: [
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        component: OrganisationSettings,
        authGateway: 'private',
    },
];

const associationRoutes = (idPrefix = '', urlPrefix = ''): Route[] => [
    {
        id: `${idPrefix}association-home`,
        path: `${urlPrefix}/a/:associationID`,
        icon: 'home',
        title: 'Association',
        permissions: [],
        breadcrumb: AssociationBreadcrumb,
        component: AssociationHome,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}association-organisations`,
        path: `${urlPrefix}/a/:associationID/organisations`,
        icon: 'group',
        title: 'Organisations',
        permissions: [],
        component: OrganisationListingContainer,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}association-events`,
        path: `${urlPrefix}/a/:associationID/events`,
        icon: 'event',
        title: 'Events',
        permissions: [
            Permissions.GET_ASSOCIATIONS,
            Permissions.GET_ASSOCIATIONS_ALL,
            Permissions.MANAGE_ASSOCIATIONS,
            Permissions.MANAGE_ASSOCIATIONS_ALL,
            //Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        component: EventsContainer,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}association-staff`,
        path: `${urlPrefix}/a/:associationID/staff`,
        icon: 'manage_accounts',
        title: 'Staff',
        permissions: [],
        component: StaffContainer,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}association-reports`,
        path: `${urlPrefix}/a/:associationID/reports`,
        icon: 'bar_chart',
        title: 'Season Reports',
        permissions: [
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
            Permissions.GET_ASSOCIATIONS,
            Permissions.GET_ASSOCIATIONS_ALL,
            Permissions.MANAGE_ASSOCIATIONS,
            Permissions.MANAGE_ASSOCIATIONS_ALL,
            Permissions.MANAGE_USERS_ASSOCIATIONS,
            Permissions.POST_USERS_ASSOCIATIONS,
            Permissions.PUT_USERS_ASSOCIATIONS,
            Permissions.DELETE_USERS_ASSOCIATIONS,
        ],
        component: AssocSeasonReportsListing,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}associations-reports-summary`,
        path: `${urlPrefix}/a/:associationID/reports/summary`,
        icon: 'bar_chart',
        title: 'Season Report',
        permissions: [
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
            Permissions.GET_ASSOCIATIONS,
            Permissions.GET_ASSOCIATIONS_ALL,
        ],
        component: AssocTeamSummaryReport,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}association-seasons`,
        path: `${urlPrefix}/a/:associationID/seasons`,
        icon: 'event_upcoming',
        title: 'Seasons',
        permissions: [],
        component: AssociationSeasonsContainer,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}association-season`,
        path: `${urlPrefix}/a/:associationID/seasons/:seasonID`,
        icon: 'event_upcoming',
        title: 'Season Team',
        permissions: [],
        breadcrumb: SeasonBreadcrumb,
        component: SeasonSingleContainer,
        authGateway: 'private',
        children: [
            {
                id: `${idPrefix}association-season-teams`,
                path: 'teams',
                icon: 'group',
                title: 'Teams',
                permissions: [],
                breadcrumb: null,
                component: SeasonTeamsContainer,
                authGateway: 'private',
            },
            {
                id: `${idPrefix}association-season-settings`,
                path: 'settings',
                icon: 'cog',
                title: 'Settings',
                permissions: [],
                breadcrumb: null,
                component: SeasonSettingsContainer,
                authGateway: 'private',
            },
        ],
    },
    {
        id: `${idPrefix}association-licences`,
        path: `${urlPrefix}/a/:associationID/licences`,
        icon: 'key',
        title: 'Licences',
        permissions: [],
        component: AssociationLicencesContainer,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}association-licences-single`,
        path: `${urlPrefix}/a/:associationID/licences/:licenceGroupID`,
        icon: 'key',
        title: 'Licences',
        permissions: [],
        breadcrumb: null,
        component: AssociationLicenceSingleContainer,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}association-settings`,
        path: `${urlPrefix}/a/:associationID/settings`,
        icon: 'settings',
        title: 'Settings',
        permissions: [],
        component: AssociationSettings,
        authGateway: 'private',
    },
];

export const routes = [
    ...globalRoutes,
    ...userRoutes,
    ...teamRoutes(),
    ...teamRoutes('organisations-', '/o/:organisationID'),
    ...teamRoutes(
        'association-organisations-',
        '/a/:associationID/o/:organisationID'
    ),
    ...organisationRoutes(),
    ...organisationRoutes('associations-', '/a/:associationID'),
    ...associationRoutes(),
];

const flattenArray = (arr: Route[]) =>
    arr.reduce((result: Route[], val: Route) => {
        result.push(val);

        const prevPath = isArray(val.path) ? val.path[0] : val.path;

        if (val.children) {
            result.push(
                ...flattenArray(
                    val.children.map((child) => ({
                        ...child,
                        path: `${prevPath}/${child.path}`,
                    }))
                )
            );
        }

        return result;
    }, []);

const flattenedRoutes = flattenArray(routes);

const getRoutesByID = (ids: string[]) =>
    flattenedRoutes
        .filter((route) => ids.includes(route.id))
        .sort((a, b) => ids.indexOf(a.id) - ids.indexOf(b.id));

export const getRouteByID = (id: string) =>
    flattenedRoutes.find((route) => id === route.id);

// Menus

export const TopMenuItems = getRoutesByID([
    //'user-events',
    'user-subscriptions',
    'help',
]);

export const AssociationMenuItems = getRoutesByID([
    'association-organisations',
    'association-events',
    'association-staff',
    'association-reports',
    'association-seasons',
    'association-licences',
    'association-settings',
]);

export const OrganisationMenuItems = getRoutesByID([
    'organisations-teams',
    //'organisations-events',
    'organisations-staff',
    'organisations-reports',
    'organisations-licences',
    'organisation-notes',
    'organisations-settings',
]);

export const TeamMenuItems = getRoutesByID([
    'teams-events',
    'teams-staff',
    'teams-players',
    'teams-reports',
    'teams-seasons',
    'teams-licences',
    'teams-notes',
    'teams-settings',
]);

export const UserMenuItems = getRoutesByID([
    'user-profile',
    'user-account',
    'user-subscriptions',
    'logout',
]);

export const AccountMenuItems = getRoutesByID([
    'user-profile',
    'user-account',
    'user-subscriptions',
    'logout',
]);
