import { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import ImportForm from '../../components/ImportForm';
import PageHeader from '../../layout/PageHeader';
import DocumentHead from '../../components/DocumentHead';

import { useCreateTeamMutation } from '../../api/teams';
import {
    useCreateOrganisationTeamMutation,
    useGetOrganisationQuery,
} from '../../api/organisations';
import { useGetMeQuery } from '../../api/core';

import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';

import {
    teamAgeOptions,
    teamGenderOptions,
    teamGradeOptions,
    teamTypeOptions,
} from '../../util/constants';
import EntityDesignerInput from '../../components/EntityDesignerInput';
import EntityPreview from '../../components/EntityPreview';

import RookieButton from '../../components/RookieButton';
import PageContainer from '../../layout/PageContainer';

const STATUS_SUCCESS = 'success';
const STATUS_ERROR = 'error';
const STATUS_LOADING = 'loading';

const columns = [
    {
        field: 'design',
        header: 'Kit',
        component: (options) => <EntityDesignerInput options={options} />,
        style: { textAlign: 'left' },
        required: true,
        body: (data) => (
            <EntityPreview
                design={data.design}
                style={{ width: '40px', height: '40px', margin: 0 }}
            />
        ),
        hidden: true,
    },
    {
        field: 'entityCategoryID',
        header: 'Type',
        required: true,
        component: (options) => (
            <Dropdown
                onChange={(e) => options.editorCallback(e.target.value)}
                options={teamTypeOptions}
                placeholder="Select Type"
                value={options.value}
                style={{ minWidth: '200px' }}
            />
        ),
        hidden: true,
    },
    {
        field: 'teamName',
        header: 'Team Name',
        required: true,
        component: (options) => (
            <InputText
                onChange={(e) => options.editorCallback(e.target.value)}
                placeholder="Team Name"
                value={options.value}
                style={{ minWidth: '200px' }}
            />
        ),
    },
    {
        field: 'shortName',
        header: 'Short Name',
        required: true,
        component: (options) => (
            <InputText
                value={options.value}
                placeholder="Short Name"
                maxLength={4}
                style={{ minWidth: '200px' }}
                onChange={(event) => {
                    const shortNameVal = event.target.value
                        .replace(/[^a-zA-Z]/g, '')
                        .toUpperCase();

                    options.editorCallback(shortNameVal);
                }}
            />
        ),
    },
    {
        field: 'gender',
        header: 'Gender',
        required: true,

        component: (options) => (
            <Dropdown
                onChange={(e) => options.editorCallback(e.target.value)}
                options={teamGenderOptions}
                placeholder="Select Gender"
                value={options.value}
                style={{ minWidth: '200px' }}
            />
        ),
    },
    {
        field: 'age',
        header: 'Age',
        required: true,
        component: (options) => (
            <Dropdown
                onChange={(e) => options.editorCallback(e.target.value)}
                options={teamAgeOptions}
                placeholder="Select Age"
                value={options.value}
                style={{ minWidth: '200px' }}
            />
        ),
    },
    {
        field: 'grade',
        header: 'Grade',
        component: (options) => (
            <Dropdown
                editable
                onChange={(e) => options.editorCallback(e.target.value)}
                options={teamGradeOptions}
                placeholder="Select Grade (Optional)"
                value={options.value}
                style={{ minWidth: '200px' }}
            />
        ),
    },
    {
        field: 'seasonName',
        header: 'Season',
        required: true,
        component: (options) => (
            <InputText
                onChange={(e) => options.editorCallback(e.target.value)}
                placeholder="Season Name"
                value={options.value}
                style={{ minWidth: '200px' }}
            />
        ),
        hidden: true,
    },
    {
        field: 'teamJoinMode',
        header: 'Join Mode',
        component: (options) => (
            <Dropdown
                onChange={(e) => options.editorCallback(e.target.value)}
                options={[
                    {
                        label: 'Enabled',
                        value: 'Enabled',
                    },
                    {
                        label: 'Single Use',
                        value: 'SingleUse',
                    },
                    {
                        label: 'Disabled',
                        value: 'Disabled',
                    },
                ]}
                placeholder="Select Mode"
                value={options.value}
                style={{ minWidth: '200px' }}
            />
        ),
    },
    {
        field: 'teamJoinRole',
        header: 'Join Role',
        component: (options) => (
            <Dropdown
                onChange={(e) => options.editorCallback(e.target.value)}
                options={[
                    {
                        label: 'Admin',
                        value: 'teamsAdmin',
                    },
                    {
                        label: 'Viewer',
                        value: 'teamsViewer',
                    },
                ]}
                placeholder="Select Mode"
                value={options.value}
                style={{ minWidth: '200px' }}
            />
        ),
    },
];

const TeamsImport = (props) => {
    const [importedData, setImportedData] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const toast = useRef(null);

    const { organisationID } = useParams();

    const navigate = useNavigate();

    // API Handling
    const { data: dataUser, isError } = useGetMeQuery();
    const { data: dataOrg } = useGetOrganisationQuery(
        { organisationID },
        { skip: !organisationID }
    );

    const [createUserTeam] = useCreateTeamMutation();
    const [createOrgTeam] = useCreateOrganisationTeamMutation();

    const createTeam = organisationID ? createOrgTeam : createUserTeam;

    const handleSubmit = async () => {
        const userID = dataUser && dataUser.data && dataUser.data.userID;
        let successCount = 0;

        setIsSubmitting(true);

        const delay = (ms = 50) =>
            new Promise((resolve) => setTimeout(resolve, ms));

        Promise.allSettled(
            importedData.map(async (data, index) => {
                return delay(index * 300).then(async () => {
                    updateRowStatus(index, STATUS_LOADING);

                    return createTeam({
                        userID,
                        organisationID,
                        ...data,
                        ...(dataOrg?.data
                            ? { design: dataOrg.data.design }
                            : {}),
                        seasonName: new Date().getFullYear().toString(),
                        entityCategoryID: 'afl_club_team',
                        entitySportID: 'aus_football_au',
                    }).then((response) => {
                        if (response.data) {
                            successCount++;
                            updateRowStatus(index, STATUS_SUCCESS);
                        } else {
                            updateRowStatus(index, STATUS_ERROR);
                        }
                    });
                });
            })
        ).then(() => {
            setIsSubmitting(false);

            toast.current.show({
                severity:
                    successCount === 0
                        ? 'error'
                        : successCount === importedData.length
                        ? 'success'
                        : '',
                className:
                    successCount === 0 || successCount === importedData.length
                        ? 'p-toast-message--is-inline'
                        : '',
                summary: `${successCount}/${importedData.length} Imported`,
                detail: successDetailContent(successCount, importedData.length),
                sticky: true,
            });
        });
    };

    const successDetailContent = (count, maxCount) => {
        return (
            <>
                {maxCount === count ? (
                    <RookieButton
                        severity="secondary"
                        className="toast-action"
                        label="View"
                        onClick={() =>
                            navigate('/', {
                                replace: true,
                            })
                        }
                    />
                ) : count > 0 ? (
                    <div className="button-group">
                        <RookieButton
                            severity="warning"
                            className="toast-action"
                            label="Clear And Retry"
                            onClick={() => handleRetryImport(count)}
                        />
                        <RookieButton
                            severity="secondary"
                            className="toast-action"
                            label="View Imported"
                            onClick={() =>
                                navigate('/', {
                                    replace: true,
                                })
                            }
                        />
                    </div>
                ) : (
                    <RookieButton
                        severity="secondary"
                        className="toast-action"
                        label="Retry"
                        onClick={() => handleSubmit()}
                    />
                )}
            </>
        );
    };

    const updateRowStatus = (rowIndex, status) => {
        setImportedData((prevData) =>
            prevData.map((item, index) =>
                index === rowIndex ? { ...item, status } : item
            )
        );
    };

    const handleImport = (data) => {
        setImportedData(data);
    };

    const handleClearAll = () => {
        setImportedData([]);
    };

    const handleClearCompleted = () => {
        setImportedData((prevState) =>
            prevState.filter((data) => data.status !== STATUS_SUCCESS)
        );
    };

    const handleRetryImport = (completedCount) => {
        if (completedCount > 0) {
            handleClearCompleted();
        }
        handleSubmit();
    };

    const handleFileError = () => {
        toast.current.show({
            severity: 'error',
            summary: `File type not supported`,
            detail: 'Import a CSV or Excel Sheet',
            life: 5000,
        });
    };

    const handleRemoveRow = (index) => {
        setImportedData((data) => [
            ...data.slice(0, index),
            ...data.slice(index + 1),
        ]);
    };

    const handleAddRow = (data) => {
        setImportedData(data);
    };

    const handleEditComplete = (data) => {
        setImportedData(data);
    };

    return (
        <>
            <DocumentHead
                title="Team Import - Rookie Me Hub"
                description="Team import page"
            />
            <PageContainer>
                <PageHeader title="Teams" showBreadcrumbs={false} />
                <ImportForm
                    columns={columns}
                    data={importedData}
                    isError={isError}
                    isSubmitting={isSubmitting}
                    onAddRow={handleAddRow}
                    onClearAll={handleClearAll}
                    onClearCompleted={handleClearCompleted}
                    onImportError={handleFileError}
                    onEditComplete={handleEditComplete}
                    onRemoveRow={handleRemoveRow}
                    onSubmit={handleSubmit}
                    onImportComplete={handleImport}
                />
                <Toast ref={toast} />
            </PageContainer>
        </>
    );
};

export default TeamsImport;
