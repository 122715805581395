import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { generatePath } from 'react-router-dom';
import { isEqual } from 'lodash';

import { apiEndpoints, prepareHeaders } from './apiEndpoints';
import {
    Organisation,
    OrganisationFormData,
    OrganisationResponse,
} from '../types/organisation';
import { Team, TeamForm, TeamsResponse } from '../types/team';

export const organisationsApi = createApi({
    reducerPath: 'organisationsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
        prepareHeaders,
    }),
    tagTypes: ['Organisation', 'Team'],
    endpoints: (builder) => ({
        getOrganisation: builder.query<
            OrganisationResponse,
            OrganisationFormData
        >({
            query: ({ organisationID }) => ({
                url: generatePath(apiEndpoints.getOrganisation.url, {
                    organisationID,
                }),
                method: apiEndpoints.getOrganisation.method,
                params: { expand: 'jumperKit' },
            }),
        }),
        createOrganisation: builder.mutation<
            Organisation,
            OrganisationFormData
        >({
            query: ({ userID, ...data }) => ({
                url: generatePath(apiEndpoints.createOrganisation.url, {
                    userID,
                }),
                method: apiEndpoints.createOrganisation.method,
                body: data,
            }),
            invalidatesTags: ['Organisation'],
        }),
        updateOrganisation: builder.mutation<
            Organisation,
            OrganisationFormData
        >({
            query: ({ organisationID, ...data }) => ({
                url: generatePath(apiEndpoints.updateOrganisation.url, {
                    organisationID,
                }),
                method: apiEndpoints.updateOrganisation.method,
                body: data,
            }),
            invalidatesTags: ['Organisation'],
        }),
        upsertOrganisation: builder.mutation<
            Organisation,
            OrganisationFormData
        >({
            query: ({ organisationID, ...data }) => ({
                url: !organisationID
                    ? apiEndpoints.createOrganisation.url
                    : generatePath(apiEndpoints.updateOrganisation.url, {
                          organisationID,
                      }),
                method: !organisationID
                    ? apiEndpoints.createOrganisation.method
                    : apiEndpoints.updateOrganisation.method,
                body: data,
            }),
            invalidatesTags: ['Organisation'],
        }),
        deleteOrganisation: builder.mutation<void, OrganisationFormData>({
            query: ({ organisationID }) => ({
                url: generatePath(apiEndpoints.deleteOrganisation.url, {
                    organisationID,
                }),
                method: apiEndpoints.deleteTeam.method,
            }),
            invalidatesTags: ['Organisation'],
        }),

        /**
         *
         * Organisation Teams
         *
         **/
        getOrganisationTeams: builder.query<
            TeamsResponse,
            OrganisationFormData
        >({
            query: ({ organisationID, cursor, entityStatus }) => ({
                url: generatePath(apiEndpoints.getOrganisationTeams.url, {
                    organisationID,
                }),
                method: apiEndpoints.getOrganisationTeams.method,
                params: {
                    cursor,
                    entityStatus,
                },
            }),
            serializeQueryArgs: ({ endpointName }) => {
                return endpointName;
            },
            merge: (currentCache, newItems) => {
                if (
                    currentCache.lastEvaluatedKey &&
                    newItems.lastEvaluatedKey &&
                    !isEqual(
                        currentCache.lastEvaluatedKey,
                        newItems.lastEvaluatedKey
                    )
                ) {
                    currentCache.data = currentCache.data.concat(newItems.data);
                    currentCache.lastEvaluatedKey = newItems.lastEvaluatedKey;
                } else {
                    currentCache.data = newItems.data;
                }
            },
            forceRefetch({ currentArg, previousArg }) {
                return !isEqual(currentArg, previousArg);
            },
            providesTags: (result) => {
                return result && result.data
                    ? [
                          ...result.data.map(({ teamID }) => ({
                              type: 'Team' as const,
                              teamID,
                          })),
                          'Team',
                      ]
                    : ['Team'];
            },
        }),
        createOrganisationTeam: builder.mutation<Team, TeamForm>({
            query: ({ userID = null, organisationID, ...data }) => ({
                url: generatePath(apiEndpoints.createOrganisationTeam.url, {
                    organisationID,
                }),
                method: apiEndpoints.createOrganisationTeam.method,
                body: data,
            }),
            invalidatesTags: ['Team'],
        }),
        updateOrganisationTeam: builder.mutation<Team, TeamForm>({
            query: ({ organisationID, teamID, ...data }) => ({
                url: generatePath(apiEndpoints.updateOrganisationTeam.url, {
                    organisationID,
                    teamID,
                }),
                method: apiEndpoints.updateOrganisationTeam.method,
                body: data,
            }),
            invalidatesTags: ['Team'],
        }),
        upsertOrganisationTeam: builder.mutation<Team, TeamForm>({
            query: ({ teamID, organisationID, ...data }) => ({
                url: generatePath(
                    teamID
                        ? apiEndpoints.updateOrganisationTeam.url
                        : apiEndpoints.createOrganisationTeam.url,
                    {
                        teamID,
                        organisationID,
                    }
                ),
                method: teamID
                    ? apiEndpoints.updateOrganisationTeam.method
                    : apiEndpoints.createOrganisationTeam.method,
                body: data,
            }),
            invalidatesTags: ['Team'],
        }),
        deleteOrganisationTeam: builder.mutation<
            Team,
            { organisationID: string; teamID: string }
        >({
            query: (params) => ({
                url: generatePath(
                    apiEndpoints.deleteOrganisationTeam.url,
                    params
                ),
                method: apiEndpoints.deleteOrganisationTeam.method,
            }),
            invalidatesTags: ['Team'],
        }),
        archiveOrganisationTeam: builder.mutation<
            Team,
            { organisationID: string; teamID: string }
        >({
            query: (params) => ({
                url: generatePath(
                    apiEndpoints.archiveOrganisationTeam.url,
                    params
                ),
                method: apiEndpoints.archiveOrganisationTeam.method,
                body: {
                    teamStatus: 'Archived',
                },
            }),
            invalidatesTags: ['Team'],
        }),
    }),
});

export const {
    useGetOrganisationQuery,
    useCreateOrganisationMutation,
    useUpdateOrganisationMutation,
    useDeleteOrganisationMutation,
    useUpsertOrganisationMutation,
    useGetOrganisationTeamsQuery,
    useLazyGetOrganisationTeamsQuery,
    useCreateOrganisationTeamMutation,
    useUpdateOrganisationTeamMutation,
    useDeleteOrganisationTeamMutation,
    useArchiveOrganisationTeamMutation,
    useUpsertOrganisationTeamMutation,
} = organisationsApi;
