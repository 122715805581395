import { ColumnBodyOptions } from 'primereact/column';
import { Skeleton } from 'primereact/skeleton';

import { Avatar } from 'primereact/avatar';
import { formatTime, getFirstChars } from '../../util/helper';

import { GameStat } from '../../types/reports';
import Icon from '../../components/Icon';
import { BaseEntityType } from '../../types/common';
import EntityAvatar from '../../components/EntityAvatar';
import RookieButton from '../../components/RookieButton';
import { result } from 'lodash';

export const playerNameCell = (
    data: GameStat,
    options: ColumnBodyOptions
): React.ReactNode => {
    if (!data || !data.player) {
        return <Skeleton width="2rem" height="1rem" />;
    }

    let fullName = '';

    if (data.player.playerID) {
        fullName = `${data.player.firstName} ${data.player.lastName}`;
    } else {
        fullName =
            data.playerID === 'team'
                ? 'Team (Unassigned)'
                : data.playerID === 'opposition'
                ? 'Opposition'
                : `Unknown Player (${data.playerID})`;
    }

    return (
        <div className="detail-cell">
            <Avatar
                label={
                    data.player.playerID
                        ? getFirstChars(fullName, 2).toUpperCase()
                        : '?'
                }
                image={data.player && data.player.playerImageURL}
                shape="circle"
            />
            <span>{fullName}</span>

            {data.timeInjury > 0 && (
                <RookieButton
                    style={{
                        width: '24px',
                        height: '24px',
                        color: '#999',
                    }}
                    tooltip={`${formatTime(
                        data.timeInjury
                    )} time spent in injury.`}
                    tooltipOptions={{
                        showDelay: 500,
                        style: { maxWidth: '200px' },
                    }}
                    severity="secondary"
                    text={true}
                    icon="personal_injury"
                />
            )}
        </div>
    );
};

export const playerGroupCell = (
    data: GameStat,
    options: ColumnBodyOptions
): React.ReactNode => {
    if (!data || !data.player) {
        return <Skeleton width="2rem" height="1rem" />;
    }

    return (
        <Avatar
            style={{
                background: data.player ? data.player.colour : 'transparent',
                width: '24px',
                height: '16px',
                border: '1px solid #e5e5e5',
            }}
        />
    );
};

export const timeCell = (
    data: GameStat,
    options: ColumnBodyOptions
): React.ReactNode => {
    if (!data) {
        return <Skeleton width="2rem" height="1rem" />;
    }

    const statID = options.field;
    const val = data[statID];
    const totalTime = data.PT;

    if (statID.includes('count')) {
        return val;
    }

    if (statID === 'PGT') {
        return isNaN(val) ? '-' : `${Math.round(val * 100)}%`;
    }

    return Math.round((val / totalTime) * 100) + '%';
};

export const textCell = (
    data: GameStat,
    options: ColumnBodyOptions
): React.ReactNode => {
    if (!data) {
        return <Skeleton width="2rem" height="1rem" />;
    }

    const value: string = result(data, options.field);

    return value ? value : '-';
};

export const percentageCell = (
    data: GameStat,
    options: ColumnBodyOptions
): React.ReactNode => {
    if (!data) {
        return <Skeleton width="2rem" height="1rem" />;
    }

    return Math.round(Number(result(data, options.field)) * 100) + '%';
};

export const playerAlertTargetCell = (
    pgt: number,
    target: number
): React.ReactNode => {
    if (!pgt && pgt !== 0) {
        return <Skeleton width="2rem" height="1rem" />;
    }

    const checked = pgt >= target;
    return (
        <Icon
            fill
            style={{
                color: checked ? '#11b411' : '#fb4747',
            }}
            title={'PGT ' + Math.round(pgt * 100) + '%'}
            name={checked ? 'check_circle' : 'cancel'}
        />
    );
};

export const teamHeader = (teamName: string, teamID?: string) => {
    return (
        <div className="detail-cell">
            <EntityAvatar
                entityType={BaseEntityType.teams}
                entityID={teamID || ''}
                entityName={teamName}
            />
            <span>{teamName}</span>
        </div>
    );
};
