import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import {
    useArchiveOrganisationTeamMutation,
    useGetOrganisationTeamsQuery,
    useUpdateOrganisationTeamMutation,
} from '../../api/organisations';

import TeamListingView from './TeamListingView';
import DocumentHead from '../../components/DocumentHead';
import { ToastContext } from '../../contexts/ToastContext';
import usePermission from '../../hooks/usePermission';
import { Permissions } from '../../types/permissions';

const TeamListingContainer = () => {
    const navigate = useNavigate();
    const { organisationID } = useParams();
    const [searchParams] = useSearchParams();

    const toast = useContext(ToastContext);

    const [cursor, setCursor] = useState('');
    const [selected, setSelected] = useState([]);
    const [isArchiving, setIsArchiving] = useState(false);

    const tab = searchParams.get('tab');

    const { checkPermission } = usePermission(organisationID || '');

    const [updateTeam] = useUpdateOrganisationTeamMutation();
    const [archiveTeam] = useArchiveOrganisationTeamMutation();

    const { data, isLoading, isFetching, isError } =
        useGetOrganisationTeamsQuery({
            organisationID,
            cursor,
            entityStatus: tab === 'Archived' ? 'Archived' : 'Active',
        });

    useEffect(() => {
        // Handle redirection to "upcoming" for specific URLs with /events
        if (!tab || !['Active', 'Archived'].includes(tab)) {
            navigate(
                {
                    search: '?tab=Active',
                },
                {
                    replace: true,
                }
            );
        }
    }, [navigate, tab]);

    const handleImportClick = () => {
        const route = organisationID
            ? `/o/${organisationID}/teams/import`
            : '/teams/import';

        navigate(route);
    };

    const handleLoadMore = () => {
        const { lastEvaluatedKey } = data;
        if (lastEvaluatedKey && lastEvaluatedKey.cursor) {
            setCursor(lastEvaluatedKey.cursor);
        }
    };

    const handleGenerateCodeClick = (mode, team) => {
        updateTeam({
            organisationID: team.organisationID,
            teamID: team.teamID,
            teamJoinMode: mode,
        });
    };

    const handleTabChange = () => {
        setCursor('');
    };

    const handleArchiveSelected = async () => {
        let successCount = 0;

        setIsArchiving(true);

        await Promise.allSettled(
            selected.map(async (data, rowIndex) => {
                const response = await archiveTeam({
                    teamID: data.teamID,
                    organisationID: data.organisationID,
                });

                if (response.data) {
                    successCount++;
                }

                setSelected([]);
            })
        ).then(() => {
            setIsArchiving(false);
        });

        toast.current.show({
            severity:
                successCount === 0
                    ? 'error'
                    : successCount === selected.length
                    ? 'success'
                    : '',
            className:
                successCount === 0 || successCount === selected.length
                    ? 'p-toast-message--is-inline'
                    : '',
            summary: `${successCount}/${selected.length} teams archived`,
            life: 5000,
        });
    };

    const permissions = {
        canCreate: checkPermission([
            Permissions.POST_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
        ]),
        canDelete: checkPermission([
            Permissions.DELETE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
        ]),
        canEdit: checkPermission([
            Permissions.PUT_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
        ]),
        canView: checkPermission([
            Permissions.GET_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ]),
    };

    return (
        <>
            <DocumentHead
                title="Team Listing - Rookie Me Hub"
                description="Team listing page"
            />
            <TeamListingView
                data={data ? data.data : []}
                isError={isError}
                isLoading={isLoading}
                isFetching={isFetching}
                isArchiving={isArchiving}
                onImportClick={handleImportClick}
                onGenerateCode={handleGenerateCodeClick}
                onLoadMore={handleLoadMore}
                onTabChange={handleTabChange}
                showPagination={
                    data &&
                    data.lastEvaluatedKey &&
                    data.lastEvaluatedKey.cursor
                }
                selected={selected}
                onSetSelected={setSelected}
                onArchiveSelected={handleArchiveSelected}
                permissions={permissions}
            />
        </>
    );
};

export default TeamListingContainer;
