import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';

import {
    useDeleteTeamSeasonMutation,
    useGetTeamOwnedSeasonsQuery,
} from '../../api/seasons';

import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

import TeamSeasonsOwnedView from './TeamSeasonsOwnedView';

import { Season } from '../../types/seasons';

const TeamSeasonsOwnedContainer = () => {
    // Navigation Hooks
    const { teamID } = useParams();

    // State Hooks
    const [cursor, setCursor] = useState<string>('');
    const [selectedSeason, setSelectedSeason] = useState<Season | null>(null);
    const [showSeasonDialog, setShowSeasonDialog] = useState<boolean>(false);
    const [showSettingsDialog, setShowSettingsDialog] =
        useState<boolean>(false);

    // API Hooks
    const [deleteSeason] = useDeleteTeamSeasonMutation();

    const { data, isError, isLoading, isFetching } =
        useGetTeamOwnedSeasonsQuery(
            {
                teamID: teamID || '',
                cursor,
            },
            {
                skip: !teamID,
            }
        );

    const handleUpdate = () => {
        setShowSeasonDialog(true);
    };

    const handleDelete = () => {
        confirmDialog({
            message: `Are you sure you want to delete ${
                selectedSeason ? selectedSeason.seasonName : 'this'
            } season?`,
            header: 'Delete Confirmation',
            acceptClassName: 'p-button-danger',
            accept: () =>
                teamID &&
                selectedSeason &&
                deleteSeason({
                    seasonID: selectedSeason.seasonID,
                    teamID,
                }),
        });
    };

    const handleSelect = (season: Season) => {
        setSelectedSeason(season);
        setShowSettingsDialog(true);
    };

    const handleSetFocus = (season: Season) => {
        setSelectedSeason(season);
    };

    const handleLoadMore = () => {
        if (data?.lastEvaluatedKey?.cursor) {
            setCursor(data.lastEvaluatedKey.cursor);
        }
    };

    const handleShowSeasonDialog = () => {
        setShowSeasonDialog(true);
    };

    const handleCloseSeasonDialog = () => {
        setShowSeasonDialog(false);
    };

    const handleShowSettingsDialog = () => {
        setShowSettingsDialog(true);
    };

    const handleCloseSettingsDialog = () => {
        setShowSettingsDialog(false);
    };

    const handleCreate = () => {
        setSelectedSeason(null);
        setShowSeasonDialog(true);
    };

    return (
        <>
            <TeamSeasonsOwnedView
                data={data?.data || []}
                isLoading={isLoading}
                isError={isError}
                isFetching={isFetching}
                onCreate={handleCreate}
                onDelete={handleDelete}
                onLoadMore={handleLoadMore}
                onSelect={handleSelect}
                onUpdate={handleUpdate}
                selectedSeason={selectedSeason}
                setFocus={handleSetFocus}
                showPagination={!isEmpty(cursor)}
                showSeasonDialog={showSeasonDialog}
                showSettingsDialog={showSettingsDialog}
                onShowSeasonDialog={handleShowSeasonDialog}
                onCloseSeasonDialog={handleCloseSeasonDialog}
                onShowSettingsDialog={handleShowSettingsDialog}
                onCloseSettingsDialog={handleCloseSettingsDialog}
            />
            <ConfirmDialog />
        </>
    );
};

export default TeamSeasonsOwnedContainer;
