import { Link } from 'react-router-dom';
import PageHeader from '../../layout/PageHeader';
import Icon from '../../components/Icon';
import { Tag } from 'primereact/tag';
import { Me } from '../../types/user';
import PageContainer from '../../layout/PageContainer';

interface Report {
    title: string;
    path: string;
    content: string;
    icon: string;
    locked?: boolean;
}

interface ReportCardGroup {
    title: string;
    items: Report[];
}

interface ReportsListingProps {
    user: Me;
}

const ReportCard = (props: Report) => {
    return (
        <Link
            to={props.locked ? '#' : props.path}
            className={`report-card ${props.locked ? 'is-locked' : ''}`}
        >
            <Icon name={props.icon} size="large" />
            <div>
                <h3 style={{ marginBottom: '5px' }}>
                    {props.title}{' '}
                    {props.locked && (
                        <Tag style={{ verticalAlign: 'middle', color: '#fff' }}>
                            Upgrade
                        </Tag>
                    )}
                </h3>
                <p
                    style={{
                        opacity: '0.7',
                        lineHeight: '1.3',
                        fontSize: '14px',
                    }}
                >
                    {props.content}
                </p>
            </div>
        </Link>
    );
};

const ReportSection = (props: ReportCardGroup) => {
    return (
        <div
            style={{
                marginBottom: '20px',
                paddingBottom: '20px',
                borderBottom: '1px solid #e5e5e5',
            }}
        >
            <h4 style={{ marginBottom: '10px' }}>{props.title}</h4>
            <div style={{ display: 'flex', gap: '20px' }}>
                {props.items.map((item) => (
                    <ReportCard {...item} />
                ))}
            </div>
        </div>
    );
};

const AssocSeasonReportsListing = (props: ReportsListingProps) => {
    const reportSections: ReportCardGroup[] = [
        /*{
            title: 'Game',
            items: [
                {
                    title: 'Summary',
                    content:
                        'Season data for FGT score, data integrity, win/loss ratio, scoring accuracy and more.',
                    icon: 'sports',
                    path: 'summary',
                },
            ],
        },*/
        {
            title: 'Coming soon...',
            items: [
                {
                    title: 'Summary',
                    content:
                        'Season data for FGT score, data integrity, win/loss ratio, scoring accuracy and more.',
                    icon: 'sports',
                    path: '',
                },
                {
                    title: 'Percent Game Time',
                    content:
                        'Report to monitor percentage game time of all players across all clubs.',
                    icon: 'analytics',
                    path: '',
                },
                {
                    title: 'Injury Report',
                    content:
                        'Insight into which players for a given week became injured during a match across all clubs.',
                    icon: 'analytics',
                    path: '',
                },
                {
                    title: 'Disciplinary Report',
                    content:
                        'A report highlighting any players placed in the disciplinary bucket for a gievn week across all clubs.',
                    icon: 'analytics',
                    path: '',
                },
                {
                    title: 'Absence Report',
                    content:
                        'Report highlighting players that have been absent for a given game and season across all clubs.',
                    icon: 'analytics',
                    path: '',
                },
            ],
        },
    ];

    return (
        <PageContainer>
            <PageHeader title="Season Reports" />
            {reportSections.map((section) => (
                <ReportSection title={section.title} items={section.items} />
            ))}
        </PageContainer>
    );
};

export default AssocSeasonReportsListing;
