import { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import PageHeader from '../../layout/PageHeader';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Skeleton } from 'primereact/skeleton';
import { useGetOrganisationTeamsQuery } from '../../api/organisations';
import { useLazyGetTeamSeasonSummaryReportQuery } from '../../api/reports';
import {
    useLazyGetTeamParticipatingSeasonsQuery,
    useLazyGetTeamOwnedSeasonsQuery,
} from '../../api/seasons';
import { unionBy } from 'lodash';
import { Tag } from 'primereact/tag';
import Icon from '../../components/Icon';
import { columns, config } from './configSummary';
import { Tooltip } from 'primereact/tooltip';
import { Dropdown } from 'primereact/dropdown';
import { Season } from '../../types/seasons';
import { StatConfig, SeasonGameSummary } from '../../types/reports';
import RookieButton from '../../components/RookieButton';
import PageContainer from '../../layout/PageContainer';
import { InputSwitch } from 'primereact/inputswitch';
import { Toolbar } from 'primereact/toolbar';
import { SelectButton } from 'primereact/selectbutton';
import { ReportDataTypes } from '../../types/reports';
import playLogo from '../../assets/images/logos/rm-play-logo.png';
import { Image } from 'primereact/image';

const OrgTeamSummaryReport = () => {
    // Route Params
    const { organisationID } = useParams();

    const timestampRef = useRef(Date.now()).current;

    const [teamReports, setTeamReports] = useState({});
    const [seasonData, setSeasonData] = useState<{ [key: string]: Season[] }>(
        {}
    );
    const [activeSeason, setActiveSeason] = useState<{
        [key: string]: string;
    }>({});
    const [showInfo, setShowInfo] = useState(false);
    const [hideEmptyCol, setHideEmptyCol] = useState<boolean>(true);
    const [hideIntFGTScore, sethideIntFGTScore] = useState(false);
    const [dataType, setDataType] = useState(ReportDataTypes.Total);

    // API Hooks
    const teams = useGetOrganisationTeamsQuery(
        {
            organisationID: organisationID || '',
        },
        { skip: !organisationID }
    );

    const [fetchTeamSeasonReport] = useLazyGetTeamSeasonSummaryReportQuery();
    const [fetchTeamOwnedSeasons] = useLazyGetTeamOwnedSeasonsQuery();
    const [fetchTeamParticipatingSeasons] =
        useLazyGetTeamParticipatingSeasonsQuery();

    useEffect(() => {
        // Reset State
        setSeasonData({});

        // Fetch seasons for all teams
        teams.data?.data.forEach(({ teamID }) => {
            fetchTeamParticipatingSeasons({
                teamID,
                cursor: '',
            })
                .then((response) => {
                    const seasons = response.data?.data;
                    if (seasons) {
                        setSeasonData((prev) => ({
                            ...prev,
                            [teamID]:
                                prev && prev[teamID]
                                    ? unionBy(
                                          [...(prev[teamID] || []), ...seasons],
                                          'seasonID'
                                      )
                                    : [],
                        }));
                    }
                })
                .catch((e) => {
                    console.warn('ERROR FETCHING SEASONS', e);
                });

            fetchTeamOwnedSeasons({
                teamID,
                cursor: '',
            })
                .then((response) => {
                    const seasons = response.data?.data;

                    if (seasons) {
                        setSeasonData((prev) => ({
                            ...prev,
                            [teamID]:
                                prev && prev[teamID]
                                    ? unionBy(
                                          [...(prev[teamID] || []), ...seasons],
                                          'seasonID'
                                      )
                                    : [],
                        }));
                    }
                })
                .catch((e) => {
                    console.warn('ERROR FETCHING SEASONS', e);
                });
        });
    }, [teams, fetchTeamOwnedSeasons, fetchTeamParticipatingSeasons]);

    useEffect(() => {
        teams.data?.data.forEach(({ teamID }) => {
            if (
                !activeSeason[teamID] &&
                seasonData[teamID] &&
                seasonData[teamID][0]
            ) {
                setActiveSeason((prev) => ({
                    ...prev,
                    [teamID]: seasonData[teamID][0].seasonID,
                }));
            }
        });
    }, [activeSeason, seasonData, teams]);

    useEffect(() => {
        teams.data?.data.forEach((team) => {
            const { teamID } = team;
            const seasonID = activeSeason[teamID];

            if (seasonID) {
                fetchTeamSeasonReport({
                    seasonID,
                    teamID,
                    sessionID: timestampRef,
                })
                    .then((reqResponse) => {
                        const reportUrl = reqResponse?.data?.data.objectURL;

                        if (reportUrl) {
                            //fetch report
                            fetch(reportUrl)
                                .then((repResponse) => {
                                    return repResponse.json();
                                })
                                .then((data) => {
                                    setTeamReports((prev) => ({
                                        ...prev,
                                        [teamID]: {
                                            team,
                                            ...data,
                                            isError: false,
                                            isLoading: false,
                                        },
                                    }));
                                })
                                .catch((err) => {
                                    setTeamReports((prev) => ({
                                        ...prev,
                                        [teamID]: {
                                            error: err,
                                            isError: true,
                                            isLoading: false,
                                        },
                                    }));
                                });
                        }
                    })
                    .catch((e) => {
                        console.warn('ERROR REQUESTING REPORT', e);
                    });
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [teams, activeSeason, fetchTeamSeasonReport]);

    const handleSeasonChange = (value: any, teamID: string) => {
        setActiveSeason((prev) => ({
            ...prev,
            [teamID]: value,
        }));
    };

    const dataTypeOptions = [
        {
            label: ReportDataTypes.Total,
            value: ReportDataTypes.Total,
        },
        {
            label: ReportDataTypes.Average,
            value: ReportDataTypes.Average,
        },
    ];

    const rightToolbar = (
        <div className="p-button-group">
            <div
                className="p-button p-button-text p-button-plain"
                style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    gap: '5px',
                    border: '1px solid #ced4da',
                    padding: '8px 15px',
                    borderRadius: '2px',
                    fontSize: '14px',
                    fontWeight: 'bold',
                }}
            >
                <label
                    style={{
                        margin: '0',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        color: '#4f4f4f',
                    }}
                >
                    Hide ENV, INT & FGT Scores
                </label>
                <InputSwitch
                    checked={hideIntFGTScore}
                    onChange={(e) => sethideIntFGTScore(e.value)}
                />
            </div>
            <div
                className="p-button p-button-text p-button-plain"
                style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    gap: '5px',
                    border: '1px solid #ced4da',
                    padding: '8px 15px',
                    borderRadius: '2px',
                    fontSize: '14px',
                    fontWeight: 'bold',
                }}
            >
                <label
                    style={{
                        margin: '0',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        color: '#4f4f4f',
                    }}
                >
                    Hide Empty Cols
                </label>
                <InputSwitch
                    checked={hideEmptyCol}
                    onChange={(e) => setHideEmptyCol(e.value)}
                />
            </div>
            <SelectButton
                value={dataType}
                onChange={(e) => setDataType(e.value)}
                options={dataTypeOptions}
                //disabled={event !== ''}
            />
        </div>
    );

    const renderIntegrityScore = (
        value: number,
        rowData: SeasonGameSummary,
        stat: StatConfig
    ) => {
        const { hsArticle } = stat;

        const iconAndTooltip = (name: string, color: string) => (
            <div className={`integrity-score-icon-${rowData.eventID}`}>
                <Tooltip
                    target={`.integrity-score-icon-${rowData.eventID}`}
                    autoHide={false}
                >
                    <div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            Average Integrity Score: {value}/5
                            <RookieButton
                                style={{
                                    backgroundColor: 'transparent',
                                    border: 'none',
                                    color: '#ff6700',
                                    opacity: '0.9',
                                }}
                                text={true}
                                data-beacon-article={hsArticle}
                                icon="help"
                            />
                        </div>
                    </div>
                </Tooltip>
                <Icon name={name} size="large" style={{ color }} />
            </div>
        );

        switch (true) {
            case value === 0:
                return iconAndTooltip('mood_bad', '#CD2335');
            case value <= 1:
                return iconAndTooltip('sentiment_dissatisfied', '#AF553A');
            case value <= 2:
                return iconAndTooltip('sentiment_neutral', '#9D743C');
            case value <= 3:
                return iconAndTooltip('sentiment_satisfied', '#F08036');
            case value <= 4:
                return iconAndTooltip('mood', '#EDB024');
            case value > 4:
                return iconAndTooltip('add_reaction', '#6FBF42');
            default:
                return null;
        }
    };

    const renderFairGameScore = (value: number | string | undefined | null) => {
        if (typeof value === 'string') {
            return <span>{value}</span>;
        }

        if (value === undefined || value === null || isNaN(value)) {
            return (
                <Tag
                    style={{
                        width: '40px',
                        color: '#fff',
                        background: '#808080',
                    }}
                >
                    NA
                </Tag>
            );
        }

        let rating = {
            label: '',
            color: '',
        };

        switch (true) {
            case value < 0.5:
                rating = {
                    label: 'Below Average',
                    color: '#CD2335',
                };
                break;
            case value < 0.6:
                rating = {
                    label: 'Average',
                    color: '#EE5145',
                };
                break;
            case value < 0.75:
                rating = {
                    label: 'Good',
                    color: '#F08036',
                };
                break;
            case value < 0.9:
                rating = {
                    label: 'Very Good',
                    color: '#EDB024',
                };
                break;
            case value <= 1:
                rating = {
                    label: 'Excellent',
                    color: '#6FBF42',
                };
                break;
        }

        return (
            <Tag
                style={{
                    width: '40px',
                    color: '#fff',
                    background: rating.color,
                }}
            >
                {Math.round(value * 100)}
            </Tag>
        );
    };

    const renderHeaderCell = (stat: StatConfig) => {
        const article = stat.hsArticle;
        const desc = stat.description;

        return (
            <div>
                <Tooltip
                    target={`.cell-${stat.shortName}`}
                    autoHide={false}
                    position="top"
                    onBeforeHide={() => setShowInfo(false)}
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ color: '#fff' }}>
                            <div>{stat.name}</div>
                            {desc && showInfo && <div>{stat.description}</div>}
                        </div>

                        {desc && (
                            <RookieButton
                                style={{ flex: 'none' }}
                                onClick={() => desc && setShowInfo(!showInfo)}
                                text={true}
                                icon={showInfo ? 'close' : 'info'}
                            />
                        )}

                        {article && (
                            <RookieButton
                                style={{ flex: 'none' }}
                                text={true}
                                data-beacon-article={article}
                                icon="help"
                            />
                        )}
                    </div>
                </Tooltip>
                <span>{stat.shortName}</span>
            </div>
        );
    };

    const dataValue: any[] = Object.values(teamReports);

    const tableFooter = (
        <div className="table-disclaimer">
            <span>Report generated by</span>{' '}
            <Image height="24px" src={playLogo} alt="Rookie Me Play" />
        </div>
    );

    return (
        <PageContainer>
            <PageHeader title="Summary Report" />
            <Toolbar end={rightToolbar} />

            <DataTable
                showGridlines
                value={dataValue ? dataValue : []}
                sortField="team.teamName"
                sortOrder={1}
                columnResizeMode="expand"
                resizableColumns
                stripedRows={true}
                footer={tableFooter}
            >
                <Column
                    sortable
                    field="team.teamName"
                    header="TEAM"
                    body={(row) => {
                        if (!row || !row.team) {
                            return <Skeleton />;
                        }

                        const url = `${window.location.pathname
                            .split('/o/')
                            .slice(0, -1)
                            .join('/')}/o/${organisationID}/t/${
                            row.team.teamID
                        }`;

                        return (
                            <Link
                                to={url}
                                style={{ fontWeight: '600', color: '#000' }}
                            >
                                {row.team.teamName}
                            </Link>
                        );
                    }}
                />

                <Column
                    header="Season"
                    body={(row) => {
                        if (!row || !row.team) {
                            return <Skeleton />;
                        }

                        const allSeasons = seasonData[row.team.teamID] ?? [];
                        return (
                            <Dropdown
                                style={{ border: 'none', width: '100%' }}
                                value={activeSeason[row.team.teamID]}
                                onChange={(e) =>
                                    handleSeasonChange(e, row.teamID)
                                }
                                options={allSeasons.map((season) => ({
                                    label: season.seasonName,
                                    value: season.seasonID,
                                }))}
                            />
                        );
                    }}
                />

                {columns.map((val) => {
                    const stat = config[val as keyof typeof config];

                    if (!stat) return null;

                    if (hideIntFGTScore) {
                        if (
                            val === 'integrityScore' ||
                            val === 'fairGameTime' ||
                            val === 'environmentCoeff'
                        ) {
                            return null;
                        }
                    }

                    return (
                        <Column
                            key={stat.shortName}
                            sortable
                            field={val}
                            align="center"
                            alignHeader="center"
                            header={renderHeaderCell(stat)}
                            headerClassName={`cell-${stat.shortName}`}
                            body={(row) => {
                                if (!row) {
                                    return (
                                        <Skeleton width="2rem" height="1rem" />
                                    );
                                }
                                const value = row[val];

                                if (val === 'matchResult') {
                                    return (
                                        <div>
                                            W: {row.wins} | L: {row.losses} | D:{' '}
                                            {row.draws}
                                        </div>
                                    );
                                }

                                if (val === 'gamesPlayed') {
                                    return row.noEvents;
                                }

                                if (val === 'teamScoringAccuracy') {
                                    return `${Math.round(
                                        (value / row.noEvents) * 100
                                    )}%`;
                                }

                                if (val === 'integrityScore') {
                                    return renderIntegrityScore(
                                        parseFloat(
                                            (value / row.noEvents).toFixed(1)
                                        ),
                                        row,
                                        stat
                                    );
                                }

                                if (
                                    val === 'fairGameTime' &&
                                    row.flags.noRotations !== undefined
                                ) {
                                    return renderFairGameScore(
                                        value / row.flags.noRotations
                                    );
                                } else if (
                                    val === 'fairGameTime' &&
                                    row.flags.noRotations === undefined
                                ) {
                                    return renderFairGameScore(
                                        value / row.noEvents
                                    );
                                }

                                if (val === 'environmentCoeff') {
                                    const integrityScore = parseFloat(
                                        (
                                            row.integrityScore / row.noEvents
                                        ).toFixed(1)
                                    );

                                    let fairGameTime;

                                    if (row.flags.noRotations !== undefined) {
                                        fairGameTime =
                                            row.fairGameTime /
                                            row.flags.noRotations;
                                    } else {
                                        fairGameTime =
                                            row.fairGameTime / row.noEvents;
                                    }

                                    let evScore;

                                    if (!isNaN(row.fairGameTime)) {
                                        evScore = parseFloat(
                                            Math.round(
                                                20 * (integrityScore / 5) +
                                                    80 * fairGameTime
                                            ).toFixed(0)
                                        );
                                    } else if (
                                        !isNaN(integrityScore) &&
                                        isNaN(fairGameTime)
                                    ) {
                                        evScore = parseFloat(
                                            Math.round(
                                                100 * (integrityScore / 5)
                                            ).toFixed(0)
                                        );
                                    }

                                    if (evScore !== undefined) {
                                        return renderFairGameScore(
                                            evScore / 100
                                        );
                                    } else {
                                        return null;
                                    }
                                }

                                if (
                                    (val === 'teamScore' ||
                                        val === 'oppositionScore' ||
                                        val === 'scoreMargin' ||
                                        val === 'rotationCount') &&
                                    dataType === 'Total'
                                ) {
                                    if (stat.format) {
                                        return stat.format(row);
                                    }

                                    return value;
                                } else {
                                    if (stat.format) {
                                        return Math.round(
                                            stat.format(row) / row.noEvents
                                        );
                                    }

                                    return Math.round(value / row.noEvents);
                                }
                            }}
                        />
                    );
                })}
            </DataTable>
        </PageContainer>
    );
};

export default OrgTeamSummaryReport;
