import { UseQueryResult } from '../../types/api';
import { TeamResponse } from '../../types/team';
import { EventResponse } from '../../types/event';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useNavigate } from 'react-router-dom';
import { Me } from '../../types/user';
import RookieButton from '../../components/RookieButton';

interface Props {
    eventData: UseQueryResult<EventResponse>;
    teamData: UseQueryResult<TeamResponse>;
    user: Me;
}

const EventReports = (props: Props) => {
    const navigate = useNavigate();
    const reports = [
        {
            name: 'Time - Basic',
            description: 'Add desc',
            path: 'gt-basic',
        },
        {
            name: 'Time - Advanced',
            description: 'Add desc',
            path: 'gt-advanced',
        },
        {
            name: 'Time - Expert',
            description: 'Add desc',
            path: 'gt-expert',
            //disabled: !isAdmin,
        },
        {
            name: 'Team Stats - Basic',
            description: 'Add desc',
            path: 'ts-basic',
        },
        {
            name: 'Team Stats - Advanced',
            description: 'Add desc',
            path: 'ts-advanced',
        },
        {
            name: 'Player Stats - Advanced',
            description: 'Add desc',
            path: 'ps-advanced',
            //disabled: !isAdmin,
        },
        {
            name: 'Interchange On/Off',
            description: 'Add desc',
            path: 'interchange',
        },
        {
            name: 'Interchange Player',
            description: 'Add desc',
            path: 'interchange-player',
        },
    ];

    return (
        <DataTable
            value={reports}
            selectionMode="single"
            columnResizeMode="expand"
            resizableColumns
        >
            <Column field="name" header="Name"></Column>
            <Column
                align="right"
                field="action"
                body={(value) =>
                    value.disabled ? (
                        <RookieButton
                            severity="secondary"
                            icon="lock"
                            label="Upgrade"
                            disabled
                        />
                    ) : (
                        <RookieButton
                            onClick={() => navigate(value.path)}
                            label="Generate"
                        />
                    )
                }
            ></Column>
        </DataTable>
    );
};

export default EventReports;
