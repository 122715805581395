import {
    generatePath,
    useLocation,
    useNavigate,
    useParams,
} from 'react-router-dom';
import { format } from 'date-fns';

import ErrorDisplay from '../../components/ErrorDisplay';

import { Column, ColumnProps } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Skeleton } from 'primereact/skeleton';

import { Season } from '../../types/seasons';
import { ERROR_TYPES } from '../../types/common';

import PageHeader from '../../layout/PageHeader';
import { TabMenu } from 'primereact/tabmenu';
import RookieButton from '../../components/RookieButton';
import PageContainer from '../../layout/PageContainer';

interface Props {
    data: Season[];
    isError: boolean;
    isLoading: boolean;
    isFetching: boolean;
    onLoadMore: () => void;
    showPagination: boolean;
}

const TeamSeasonsParticipatingView = ({
    data,
    isFetching,
    isLoading,
    onLoadMore,
    showPagination,
}: Props) => {
    const { teamID } = useParams();

    const location = useLocation();
    const navigate = useNavigate();

    const footerTemplate = (
        <RookieButton
            onClick={onLoadMore}
            severity="secondary"
            label="Load more"
            icon="pending"
        />
    );

    const emptyTemplate = (
        <ErrorDisplay
            alignment="middle"
            desc={`Seasons will appear here.`}
            errorType={ERROR_TYPES.empty}
            hasReturn={false}
            proportion="compact"
            title="No Seasons"
        />
    );

    const columns = [
        {
            field: 'seasonName',
            header: 'Name',
            body: isLoading && <Skeleton />,
        },
        {
            field: 'createdAt',
            header: 'Joined',
            body: (row: Season) =>
                isLoading ? (
                    <Skeleton />
                ) : (
                    format(new Date(row.createdAt), 'MMM yyyy')
                ),
        },
    ];

    const tabs = [
        {
            id: 'owned',
            label: 'Owned',
            data: {
                path: teamID
                    ? generatePath('/t/:teamID/seasons/owned', { teamID })
                    : '',
            },
        },
        {
            id: 'participating',
            label: 'Participating',
            data: {
                path: teamID
                    ? generatePath('/t/:teamID/seasons/participating', {
                          teamID,
                      })
                    : '',
            },
        },
    ];

    return (
        <PageContainer>
            <PageHeader title="Seasons" />
            <TabMenu
                model={tabs}
                activeIndex={tabs.findIndex((tab) =>
                    location.pathname.includes(tab.data.path)
                )}
                onTabChange={(e) =>
                    e.value.data.path && navigate(e.value.data.path)
                }
            />
            <DataTable
                value={isLoading ? Array(5) : data}
                loading={isFetching && !isLoading}
                footer={!isFetching && showPagination ? footerTemplate : null}
                emptyMessage={emptyTemplate}
                selectionMode="single"
                columnResizeMode="expand"
                resizableColumns
            >
                {(isLoading || data.length > 0) &&
                    columns.map((col: ColumnProps) => (
                        <Column key={col.field} {...col} />
                    ))}
            </DataTable>
        </PageContainer>
    );
};

export default TeamSeasonsParticipatingView;
