import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    reduce,
    unionBy,
    uniq,
    groupBy,
    map,
    sortBy,
    union,
    uniqBy,
    intersectionWith,
} from 'lodash';

import { useGetEventsQuery } from '../../api/events';
import {
    useGetTeamOwnedSeasonsQuery,
    useGetTeamParticipatingSeasonsQuery,
} from '../../api/seasons';
import {
    useGetTeamSeasonStatsBasicReportQuery,
    useGetTeamSeasonStatsAdvancedReportQuery,
} from '../../api/reports';

import Icon from '../../components/Icon';
import PageHeader from '../../layout/PageHeader';
import playLogo from '../../assets/images/logos/rm-play-logo.png';
import { Image } from 'primereact/image';

import { Column } from 'primereact/column';
import { periodSuffix } from '../../util/helper';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { SelectButton } from 'primereact/selectbutton';
import { SelectItem } from 'primereact/selectitem';
import { Skeleton } from 'primereact/skeleton';
import { Toolbar } from 'primereact/toolbar';

import { columns, config } from './configStats';
import { toISOStringWithTimezone } from '../../util/helper';
import { defaultReportState } from '../reports/constants';

import { Event } from '../../types/event';
import {
    ReportDataTypes,
    ReportState,
    TeamStat,
    TeamStatShort,
} from '../../types/reports';
import { useGetTeamQuery } from '../../api/teams';
import { teamHeader } from '../reports/DataTableCells';
import { useGetStatsQuery } from '../../api/stats';
import { InputSwitch } from 'primereact/inputswitch';
import ErrorDisplay from '../../components/ErrorDisplay';
import { BaseEntityType, ERROR_TYPES } from '../../types/common';
import RookieButton from '../../components/RookieButton';
import PageContainer from '../../layout/PageContainer';

import { Mixpanel } from '../../util/mixpanel';

interface Props {
    reportType: string;
}

const TeamStatsReport = ({ reportType }: Props) => {
    // Route Params
    const { teamID } = useParams();

    // State Hooks
    const [dataType, setDataType] = useState(ReportDataTypes.Total);
    const [season, setSeason] = useState<string>();
    const [event, setEvent] = useState('');
    const [category, setCategory] = useState('');
    const [hideEmptyColumns, setHideEmptyColumns] = useState(true);
    const [reportData, setReportData] =
        useState<ReportState<TeamStat[]>>(defaultReportState);
    const [filter, setFilter] = useState('0'); // for period filter

    // Cache busting ref
    const timestampRef = useRef(Date.now()).current;
    const dataTable = useRef<DataTable<any>>(null);

    const reportParams = {
        seasonID: season || '',
        teamID: teamID || '',
        sessionID: timestampRef,
    };

    // API Hooks
    const teamData = useGetTeamQuery(
        { teamID: teamID || '' },
        { skip: !teamID }
    );
    const team = teamData?.data?.data;

    const statConfigData = useGetStatsQuery({
        sportID: team?.entitySportID as string,
    });

    const statConfig = statConfigData?.data?.data;

    const requestBasicReport = useGetTeamSeasonStatsBasicReportQuery(
        reportParams,
        {
            skip: !season || !teamID || reportType !== 'basic',
        }
    );
    const requestAdvancedReport = useGetTeamSeasonStatsAdvancedReportQuery(
        reportParams,
        {
            skip: !season || !teamID || reportType !== 'advanced',
        }
    );

    const requestReportData =
        reportType === 'advanced' ? requestAdvancedReport : requestBasicReport;

    const seasonOwnedData = useGetTeamOwnedSeasonsQuery(
        {
            teamID: teamID || '',
            cursor: '',
        },
        { skip: !teamID }
    );

    const seasonParticipatingData = useGetTeamParticipatingSeasonsQuery(
        {
            teamID: teamID || '',
            cursor: '',
        },
        { skip: !teamID }
    );

    const eventData = useGetEventsQuery({
        entityType: BaseEntityType.teams,
        entityID: teamID || '',
        to: toISOStringWithTimezone(new Date()),
        limit: '50',
    });

    // Helpers
    const mergedSeasons = useMemo(() => {
        const ownedSeasons = seasonOwnedData?.data?.data;
        const participatingSeasons = seasonParticipatingData?.data?.data;

        return ownedSeasons && participatingSeasons
            ? unionBy(ownedSeasons, participatingSeasons, 'seasonID')
            : [];
    }, [seasonOwnedData, seasonParticipatingData]);

    // Filter Options
    const eventOptions = useMemo(() => {
        let options = [
            {
                label: 'Entire Season',
                value: '',
                error: false,
            },
        ];

        const availableGames = uniq(
            reportData?.data?.map((val: TeamStat) => val.eventID)
        );

        if (eventData.data) {
            eventData.data.data.forEach((event: Event) => {
                options.push({
                    label: event.eventName,
                    value: event.eventID,
                    error: !availableGames.includes(event.eventID),
                });
            });
        }

        // Filter out options where error is true
        return options.filter((option) => !option.error);
    }, [reportData, eventData]);

    const rawData = reportData?.data;

    const totalEventsCount = useMemo(() => {
        return uniq(reportData?.data?.map((o) => o.eventID)).length;
    }, [reportData]);

    const getAverages = useCallback(
        (data: TeamStat[]) => {
            return map(
                groupBy(data, (o) => o.playerID === 'opposition'),
                (val, key) => {
                    const totals: { [key: string]: any } = reduce(
                        val,
                        (prev, curr) => {
                            Object.keys(curr).forEach((k) => {
                                if (curr.hasOwnProperty(k)) {
                                    prev[k] =
                                        k !== 'totalPGT' &&
                                        typeof curr[k] === 'number'
                                            ? (prev[k] || 0) + curr[k]
                                            : curr[k];
                                }
                            });
                            return prev;
                        },
                        {} as { [key: string]: TeamStat }
                    );

                    let averages: { [key: string]: any } = {};
                    Object.keys(totals).forEach((k) => {
                        averages[k] =
                            typeof totals[k] === 'number'
                                ? totals[k] / totalEventsCount
                                : totals[k];
                    });

                    return averages;
                }
            );
        },
        [totalEventsCount]
    );

    const getTotals = useCallback((data: TeamStat[]) => {
        return map(
            groupBy(data, (o) => o.playerID === 'opposition'),
            (val, key) => {
                return val.reduce((prev, curr) => {
                    Object.keys(curr).forEach((k) => {
                        if (curr.hasOwnProperty(k)) {
                            prev[k] =
                                k !== 'totalPGT' && typeof curr[k] === 'number'
                                    ? (prev[k] || 0) + curr[k]
                                    : curr[k];
                        }
                    });
                    return prev;
                }, {} as { [key: string]: TeamStat });
            }
        );
    }, []);

    useEffect(() => {
        const reportUrl = requestReportData?.data?.data.objectURL;

        if (reportUrl && requestReportData.isSuccess) {
            setReportData((prev) => ({
                ...prev,
                data: [],
                error: null,
                isError: false,
                isLoading: true,
                isUninitialized: false,
            }));

            //fetch report
            fetch(reportUrl)
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    setReportData((prev) => ({
                        ...prev,
                        data,
                        isError: false,
                        isLoading: false,
                    }));
                })
                .catch((err) => {
                    setReportData((prev) => ({
                        ...prev,
                        error: err,
                        isError: true,
                        isLoading: false,
                    }));
                });
        } else {
            setReportData(defaultReportState);
        }
    }, [requestReportData]);

    const categoryOptions = useMemo(() => {
        let options: SelectItem[] = [
            {
                label: 'All Categories',
                value: '',
            },
        ];

        const uniqStats = union(...(rawData || []).map((d) => Object.keys(d)));
        const uniqCategories = uniqBy(
            intersectionWith(statConfig, uniqStats, (config, statID) => {
                return config.statID === statID;
            }),
            'statCategory.name'
        );

        const sortedCategories = sortBy(
            uniqCategories,
            'statCategory.sortOrder'
        );

        sortedCategories.forEach((config) => {
            options.push({
                label: config.statCategory.name,
                value: config.statCategory.name,
            });
        });

        return options;
    }, [rawData, statConfig]);

    const seasonOptions = useMemo(() => {
        let options = mergedSeasons
            ? mergedSeasons.map((season) => ({
                  label: season.seasonName,
                  value: season.seasonID,
              }))
            : [];

        return options;
    }, [mergedSeasons]);

    const dataTypeOptions = [
        {
            label: ReportDataTypes.Total,
            value: ReportDataTypes.Total,
        },
        {
            label: ReportDataTypes.Average,
            value: ReportDataTypes.Average,
        },
    ];

    // Set the season to the first available season
    useEffect(() => {
        if (!season && mergedSeasons.length > 0) {
            setSeason(team?.defaultSeasonID || '');
        }
    }, [mergedSeasons, season, team]);

    // Set data type to totals when filtering by a single event
    useEffect(() => {
        if (event !== '' && dataType === ReportDataTypes.Average) {
            setDataType(ReportDataTypes.Total);
        }
    }, [event, dataType]);

    const exportCSV = () => {
        dataTable.current && dataTable.current.exportCSV();

        Mixpanel.track('Export Report', {
            reportType: `${reportType} Cumulative Team Game Stats`,
        });
    };

    const calculatedData = useMemo(() => {
        let data: TeamStat[] = reportData.data || [];

        // Apply event filter
        if (event !== '') {
            data = data.filter((item) => item.eventID === event);
        }

        // Filter data to only keep team and opposition
        data = data.filter(
            (item) => item.playerID === 'opposition' || item.playerID === 'team'
        );

        data = data.filter(
            (item: TeamStat) => filter === '0' || item.period === filter
        );

        // Remove the total to avoid summing both the individual periods and the total
        data = data.filter((o) => o.period !== 'total');

        return dataType === ReportDataTypes.Average
            ? getAverages(data)
            : getTotals(data);
    }, [reportData, dataType, event, getAverages, getTotals, filter]);

    const transformedData = useMemo(() => {
        return map(
            reduce(
                calculatedData,
                (result, curr) => {
                    columns[reportType as keyof typeof columns].forEach((c) => {
                        const con = statConfig?.find((o) => o.statID === c);

                        if (con) {
                            result[c] = result[c] || {
                                stat: con,
                                team: 0,
                                opposition: 0,
                            };

                            result[c][
                                curr.playerID === 'opposition'
                                    ? 'opposition'
                                    : 'team'
                            ] = curr[c];
                        }
                    });

                    return result;
                },
                {} as { [key: string]: TeamStatShort }
            )
        );
    }, [calculatedData, statConfig, reportType]);

    const filteredData = useMemo(() => {
        let filtered = transformedData;

        // Apply stat category filter
        if (category !== '') {
            filtered = filtered.filter(
                (item) => item.stat.statCategory.name === category
            );
        }

        // Apply empty result filter
        if (hideEmptyColumns) {
            filtered = filtered.filter(
                (item) => item.team > 0 || item.opposition > 0
            );
        }

        return filtered;
    }, [transformedData, hideEmptyColumns, category]);

    let totalPeriods = 0;
    let periodFilteredData: TeamStat[] = reportData.data || [];
    if (event !== '') {
        totalPeriods = periodFilteredData.reduce(
            (maxPeriod, item: TeamStat) => {
                const itemPeriod =
                    typeof item.period === 'string'
                        ? parseInt(item.period, 10)
                        : item.period;
                return itemPeriod > maxPeriod ? itemPeriod : maxPeriod;
            },
            0
        );
    }

    const periodOptions = useMemo(
        () =>
            Array.from({ length: totalPeriods }).map((u, i) => {
                const period = i + 1;
                const label = `${period}${periodSuffix(Number(period))}`;
                return {
                    label: label,
                    value: period,
                };
            }),
        [totalPeriods]
    );

    // Renders
    const leftToolbar = (
        <div className="p-button-group">
            <>
                <Dropdown
                    onChange={(e) => setSeason(e.value)}
                    value={season}
                    options={seasonOptions}
                />
                <Dropdown
                    onChange={(e) => setEvent(e.value)}
                    value={event}
                    options={eventOptions}
                    itemTemplate={(o) => {
                        return (
                            <div
                                style={{
                                    opacity: o.error ? 0.5 : 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                            >
                                {o.label} {o.error && <Icon name="error" />}
                            </div>
                        );
                    }}
                />
                {categoryOptions.length > 2 && (
                    <Dropdown
                        onChange={(e) => setCategory(e.value)}
                        value={category}
                        options={categoryOptions}
                    />
                )}
                <div className="p-buttonset">
                    <SelectButton
                        value={filter}
                        multiple={false}
                        allowEmpty={false}
                        onChange={(e) => setFilter(e.value)}
                        options={[
                            { label: 'All', value: '0' },
                            ...periodOptions,
                        ]}
                        disabled={event === ''}
                    />
                </div>
            </>
        </div>
    );

    const rightToolbar = (
        <div className="p-button-group">
            <div
                className="p-button p-button-text p-button-plain"
                style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    gap: '5px',
                    border: '1px solid #ced4da',
                    padding: '8px 15px',
                    borderRadius: '2px',
                    fontSize: '14px',
                    fontWeight: 'bold',
                }}
            >
                <label
                    style={{
                        margin: '0',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        color: '#4f4f4f',
                    }}
                >
                    Hide Empty Cols
                </label>
                <InputSwitch
                    checked={hideEmptyColumns}
                    onChange={(e) => setHideEmptyColumns(e.value)}
                />
            </div>
            <SelectButton
                value={dataType}
                onChange={(e) => setDataType(e.value)}
                options={dataTypeOptions}
                disabled={event !== ''}
            />
            <RookieButton
                type="button"
                onClick={() => exportCSV()}
                label="Export CSV"
                severity="secondary"
            />
        </div>
    );

    const renderEmpty = () => {
        let msg;

        if (event && filter !== '0') {
            msg = 'There are no stats for the selected period.';
        } else if (event) {
            msg =
                'Unable to find report. Try changing your selected season or get in touch with our support team.';
        } else {
            msg = 'There is no data for this period.';
        }
        const errorType = event
            ? ERROR_TYPES.somethingsWrong
            : ERROR_TYPES.notFound;

        return (
            <ErrorDisplay
                alignment="middle"
                title="No Data Found"
                desc={msg}
                errorType={errorType}
                hasReturn={false}
                proportion="compact"
            />
        );
    };

    const tableFooter = (
        <div className="table-disclaimer">
            <span>Report generated by</span>{' '}
            <Image height="24px" src={playLogo} alt="Rookie Me Play" />
        </div>
    );

    return (
        <PageContainer>
            <PageHeader title="Team Stat Report" />
            <Toolbar start={leftToolbar} end={rightToolbar} />
            <div style={{ maxHeight: 'auto' }}>
                <DataTable
                    ref={dataTable}
                    sortField="stat.statSortOrder"
                    sortOrder={1}
                    removableSort
                    showGridlines
                    value={
                        reportData.isLoading || reportData.isUninitialized
                            ? Array(5)
                            : filteredData
                    }
                    emptyMessage={renderEmpty()}
                    scrollHeight="100vh"
                    columnResizeMode="expand"
                    resizableColumns
                    footer={tableFooter}
                    exportFilename={`season-${reportType}-stats-report`}
                    stripedRows={true}
                >
                    <Column
                        header="Stat"
                        field="stat.statName"
                        sortable
                        body={(data: TeamStatShort) => {
                            return statConfigData.isLoading ||
                                !data ||
                                !data.stat ? (
                                <Skeleton width="2rem" height="1rem" />
                            ) : (
                                <span
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        fontWeight: 500,
                                    }}
                                >
                                    {data.stat.statName}{' '}
                                    <RookieButton
                                        style={{ opacity: '0.5' }}
                                        tooltip={
                                            config[data.stat.statID].description
                                        }
                                        text={true}
                                        severity="secondary"
                                        icon="info"
                                    />
                                </span>
                            );
                        }}
                    />

                    <Column
                        field="team"
                        sortable
                        style={{ width: '120px' }}
                        align="right"
                        alignHeader="right"
                        header={() =>
                            teamData.isLoading ? (
                                <Skeleton width="3rem" height="1rem" />
                            ) : (
                                teamHeader(team?.teamName || '', teamID)
                            )
                        }
                        body={(row) =>
                            !row || reportData.isLoading ? (
                                <Skeleton width="2rem" height="1rem" />
                            ) : row['team'] ? (
                                parseFloat(row['team'].toFixed(1))
                            ) : (
                                '-'
                            )
                        }
                        exportable={false}
                    />
                    <Column
                        field="opposition"
                        sortable
                        style={{ width: '120px' }}
                        align="right"
                        alignHeader="right"
                        header="Opposition"
                        body={(row) =>
                            !row || reportData.isLoading ? (
                                <Skeleton width="2rem" height="1rem" />
                            ) : row['opposition'] ? (
                                parseFloat(row['opposition'].toFixed(1))
                            ) : (
                                '-'
                            )
                        }
                        exportable={false}
                    />
                    <Column field="team" header="Team" hidden={true} />
                    <Column
                        field="opposition"
                        header="Opposition"
                        hidden={true}
                    />
                </DataTable>
            </div>
        </PageContainer>
    );
};

export default TeamStatsReport;
